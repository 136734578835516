export const deepCopy = (obj) => JSON.parse(JSON.stringify(obj));
export const capitalize = (str) => `${str.slice(0, 1).toUpperCase()}${str.slice(1, str.length)}`;

export const prepareBrandFormData = (data) => {
  const brand = new FormData();
  brand.append("name", data.name);
  brand.append("photoUrl", data.file);

  return brand;
};

export const prepareModelFormData = (data) => {
  const model = new FormData();
  model.append("name", data.name);
  model.append("brand", data.brand);
  model.append("photoUrl", data.file);

  return model;
};

export const prepareCarFormData = (data) => {
  const car = new FormData();
  car.append("brand", data.brand);
  car.append("model", data.model);
  car.append("trimLevel", data.trimLevel);
  car.append("rangeDescription", data.rangeDescription);
  car.append("rangeType", data.rangeType);
  car.append("equipment", data.equipment);
  car.append("intColor", JSON.stringify(data.intColor));
  car.append("extColor", JSON.stringify(data.extColor));
  if (data.files) {
    data.files.forEach((photo) => {
      car.append("rangePhotoUrls", photo);
    });
  }

  return car;
};

export const prepareTrimFormData = (data) => {
  const trim = new FormData();
  trim.append("brand", data.brand);
  trim.append("model", data.model);
  trim.append("name", data.name);
  trim.append("type", data.type.toLowerCase());
  trim.append("description", data.description);
  trim.append("equipment", data.equipment);
  if (data.files) {
    data.files.forEach((photo) => {
      trim.append("photoUrls", photo);
    });
  }

  return trim;
};

export const prepareDealFormData = (data) => {
  const deal = new FormData();
  deal.append("brand", data.brand);
  deal.append("model", data.model);
  deal.append("trim", data.trim);
  deal.append("disclaimer", data.disclaimer);
  deal.append("year", data.year);
  deal.append("type", data.type);
  deal.append("price", data.price);
  deal.append("moneyDown", data.moneyDown);
  deal.append("MSRP", data.MSRP);
  deal.append("miles", data.miles);
  deal.append("leaseTerms", data.leaseTerms);
  deal.append("equipment", data.equipment);
  deal.append("isActivityFeed", data.isActivityFeed);
  if (data.options) {
    data.options.forEach((option) => {
      deal.append("options", option);
    });
  }
  deal.append("intColor", JSON.stringify(data.intColor));
  deal.append("extColor", JSON.stringify(data.extColor));
  if (data.files) {
    data.files.forEach((photo) => {
      deal.append("photoUrls", photo);
    });
  }

  return deal;
};

export const prepareInquiryFormData = (data, modifiedCar) => {
  const inquiry = new FormData();
  data.MSRP && inquiry.append("MSRP", data.MSRP);
  data.price && inquiry.append("price", data.price);
  inquiry.append("miles", data.miles);
  data.moneyDown && inquiry.append("moneyDown", data.moneyDown);
  inquiry.append("leaseterms", data.leaseTerms);
  inquiry.append("modifiedCar", JSON.stringify(modifiedCar));
  inquiry.append("status", data.status);
  if (data.files) {
    data.files.forEach((file) => {
      inquiry.append("adminFiles", file);
    });
  }

  return inquiry;
};

export const prepareDealInquiryFormData = (data) => {
  const dealInquiry = new FormData();
  dealInquiry.append("status", data.status);
  dealInquiry.append("markedForFeed", data.markedForFeed);
  if (data.files) {
    data.files.forEach((file) => {
      dealInquiry.append("adminFiles", file);
    });
  }

  return dealInquiry;
};
