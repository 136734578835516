import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

import Table from "../common/Table";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import Spinner from "../common/Spinner";
import { getAdminActivities } from "../../actions/adminActivityActions";

import { adminActivityLabels } from "../../constants/tableData";
import { ITEMS_PER_PAGE } from "../../constants";

class AdminActivities extends Component {
  state = {
    isOpen: true,
    activePage: 1
  };

  componentDidMount() {
    this.props.getAdminActivities();
  }

  changePage = activePage => {
    this.setState({ activePage });
  };

  toggleSidebar = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { isOpen, activePage } = this.state;
    const { adminActivities, loading } = this.props.adminActivities;

    let sidebar = isOpen ? (
      <Sidebar isOpen={isOpen} toggleSidebar={this.toggleSidebar} />
    ) : (
      <i
        className="fas fa-bars"
        style={{ marginLeft: "10px", cursor: "pointer" }}
        onClick={this.toggleSidebar}
        title="Show"
      />
    );

    return (
      <div className="adminActivities">
        <Navbar />
        {sidebar}

        <div className="container-fluid fixed-right">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="display-4 text-center">Admin Activities</h1>
              <hr />
              {loading || adminActivities === null ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  <Table
                    labels={adminActivityLabels}
                    data={adminActivities.slice(
                      (activePage - 1) * ITEMS_PER_PAGE,
                      activePage * ITEMS_PER_PAGE
                    )}
                  />
                  <Pagination
                    activePage={activePage}
                    totalItemsCount={adminActivities.length}
                    onChange={this.changePage}
                    pageRangeDisplayed={5}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

AdminActivities.propTypes = {
  getAdminActivities: PropTypes.func.isRequired,
  adminActivities: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  adminActivities: state.adminActivities,
  errors: state.errors
});

export default connect(mapStateToProps, { getAdminActivities })(
  AdminActivities
);
