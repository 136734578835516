import axios from "axios";
import { ROOT_URL } from "../constants";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_TRIMS,
  CREATE_TRIM,
  DELETE_TRIM,
  TRIM_LOADING,
} from "./types";

// Get trims
export const getTrims = () => (dispatch) => {
  dispatch({ type: TRIM_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .get(ROOT_URL + "/v1/adminApi/trims")
    .then(async (res) => {
      dispatch({ type: GET_TRIMS, payload: res.data.trims });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

// Create trim
export const createTrim = (trimData, history) => (dispatch) => {
  dispatch({ type: TRIM_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post(ROOT_URL + "/v1/adminApi/trims", trimData)
    .then((res) => {
      dispatch({ type: CREATE_TRIM, payload: res.data.trim });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

// Update trim
export const updateTrim = (id, updateData) => (dispatch) => {
  axios
    .patch(ROOT_URL + "/v1/adminApi/trims/" + id, updateData)
    .then(async (res) => dispatch(getTrims()))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

// Delete trim
export const deleteTrim = (id) => (dispatch) => {
  dispatch({ type: TRIM_LOADING });
  axios
    .delete(ROOT_URL + "/v1/adminApi/trims/" + id)
    .then(async (res) => dispatch({ type: DELETE_TRIM, payload: id }))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};
