import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

import Table from "../common/Table";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import Spinner from "../common/Spinner";
import InputGroup from "../common/InputGroup";
import DeleteModal from "../modals/DeleteModal";
import CreateEditModelModal from "../modals/CreateEditModelModal";
import { getModels, createModel, updateModel, deleteModel } from "../../actions/modelActions";
import { getBrands } from "../../actions/brandActions";

import { modelLabels } from "../../constants/tableData";
import { prepareModelFormData } from "../../utils/helpers";
import { ITEMS_PER_PAGE, model_template } from "../../constants";

class Models extends Component {
  state = {
    edit: false,
    isOpen: true,
    activePage: 1,
    activeModel: null,
    deleteModal: false,
    createEditModelModal: false,
    search: "",
  };

  componentDidMount() {
    this.props.getModels();
    this.props.getBrands();
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  changePage = (activePage) => {
    this.setState({ activePage });
  };

  toggleSidebar = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  openCreateEditModelModal = (row) => {
    const activeModel = row ? row : model_template;
    const edit = Boolean(row);

    this.setState({ createEditModelModal: true, activeModel, edit });
  };

  closeCreateEditModelModal = () => {
    this.setState({ createEditModelModal: false });
  };

  createEditModel = (data) => {
    const model = prepareModelFormData(data);

    if (this.state.edit) {
      this.props.updateModel(data._id, model);
    } else {
      this.props.createModel(model);
    }

    this.setState({ createEditModelModal: false });
  };

  openDeleteModal = (activeModel) => {
    this.setState({ deleteModal: true, activeModel });
  };

  closeDeleteModal = () => {
    this.setState({ deleteModal: false, activeModel: null });
  };

  deleteModel = () => {
    const { activeModel } = this.state;
    this.props.deleteModel(activeModel._id);
    this.closeDeleteModal();
  };

  render() {
    const {
      edit,
      isOpen,
      search,
      activePage,
      activeModel,
      deleteModal,
      createEditModelModal,
    } = this.state;
    const { models, loading } = this.props.models;
    const { brands } = this.props.brands;

    let searchFilteredModels = models;
    if (search.length > 2) {
      searchFilteredModels = models.filter((model) => {
        return model.brand.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
    }

    let sidebar = isOpen ? (
      <Sidebar isOpen={isOpen} toggleSidebar={this.toggleSidebar} />
    ) : (
      <i
        className="fas fa-bars"
        style={{ marginLeft: "10px", cursor: "pointer" }}
        onClick={this.toggleSidebar}
        title="Show"
      />
    );

    return (
      <div className="models">
        <Navbar />
        {sidebar}
        <div className="container-fluid fixed-right">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="display-4 text-center">Models</h1>
              <hr />
            </div>
            <div className="col-lg-9">
              <button
                className="btn btn-info"
                onClick={() => this.openCreateEditModelModal(null)}
                style={{ marginBottom: "5px" }}
              >
                Add Model
              </button>
            </div>
            <div className="col-lg-3">
              <InputGroup
                name="search"
                type="text"
                placeholder="Search"
                value={search}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {loading || models === null ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  <Table
                    labels={modelLabels}
                    data={searchFilteredModels.slice(
                      (activePage - 1) * ITEMS_PER_PAGE,
                      activePage * ITEMS_PER_PAGE
                    )}
                    actions={[
                      {
                        type: "edit",
                        action: this.openCreateEditModelModal,
                      },
                      {
                        type: "delete",
                        action: this.openDeleteModal,
                      },
                    ]}
                  />
                  <Pagination
                    activePage={activePage}
                    totalItemsCount={models.length}
                    onChange={this.changePage}
                    pageRangeDisplayed={5}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </React.Fragment>
              )}
              {createEditModelModal && (
                <CreateEditModelModal
                  edit={edit}
                  data={activeModel}
                  brands={brands}
                  isOpen={createEditModelModal}
                  closeModal={this.closeCreateEditModelModal}
                  createEditModel={this.createEditModel}
                />
              )}
              {deleteModal && (
                <DeleteModal
                  title="Delete Model"
                  description="Are you sure you want to delete this model?"
                  isOpen={deleteModal}
                  closeModal={this.closeDeleteModal}
                  deleteAction={this.deleteModel}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Models.propTypes = {
  getBrands: PropTypes.func.isRequired,
  getModels: PropTypes.func.isRequired,
  createModel: PropTypes.func.isRequired,
  updateModel: PropTypes.func.isRequired,
  deleteModel: PropTypes.func.isRequired,
  models: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  brands: state.brands,
  models: state.models,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getBrands,
  getModels,
  createModel,
  updateModel,
  deleteModel,
})(Models);
