import axios from "axios";
import { ROOT_URL } from "../constants";
import { GET_ERRORS, GET_ADMIN_ACTIVITIES, CLEAR_ERRORS } from "./types";

// Get admins
export const getAdminActivities = () => dispatch => {
  dispatch({ type: CLEAR_ERRORS });
  axios
    .get(ROOT_URL + "/v1/adminApi/adminActivities")
    .then(async res => {
      dispatch({
        type: GET_ADMIN_ACTIVITIES,
        payload: res.data.adminActivities
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};
