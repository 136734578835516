import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { capitalize } from "../../utils/helpers";

const isDate = (key) => key === "createdAt" || key === "updatedAt";
const isPhotoUrl = (key) => key === "photoUrl";
const isActive = (key) => key === "isActive";
const isAdmin = (key) => key === "admin";
const isEmail = (key) => key === "email";
const isUser = (key) => key === "user";
const isDeal = (key) => key === "deal";
const isCar = (key) => key === "trim";

const ActionItem = ({ row, action, index }) => {
  if (action.type === "editStatus") {
    return (
      <React.Fragment>
        <button
          className={"btn " + (row.isActive ? "btn-info" : "btn-success")}
          style={{ width: "100px" }}
          onClick={() => action.action(row)}
        >
          {row.isActive ? "Block" : "Activate"}
        </button>
      </React.Fragment>
    );
  } else if (action.type === "chat") {
    const icon = require(`../../static/icons/${action.type}.svg`);
    let unreadMessages = row.inquiryMessages.filter((msg) => !msg.adminRead);
    return (
      <React.Fragment>
        <img
          alt=""
          src={icon}
          className="action-icon"
          title={capitalize(action.type)}
          onClick={() => action.action(row, index)}
        />
        {unreadMessages.length !== 0 ? (
          <span className="badge badge-pill badge-danger">{unreadMessages.length}</span>
        ) : (
          ""
        )}
      </React.Fragment>
    );
  } else {
    const icon = require(`../../static/icons/${action.type}.svg`);
    return (
      <React.Fragment>
        <img
          alt=""
          src={icon}
          className="action-icon"
          title={capitalize(action.type)}
          onClick={() => action.action(row, index)}
        />
      </React.Fragment>
    );
  }
};
const TableHeaderCell = ({ label, index }) => (
  <th key={label.key} className={index === 0 ? "left" : "center"}>
    {label.label}
  </th>
);
const TableBodyCell = ({ row, label, index }) => (
  <td key={label.key} className="table-cell">
    {isDate(label.key) ? (
      moment(row[label.key]).format("h:mma MM-DD-YYYY")
    ) : isPhotoUrl(label.key) ? (
      <img
        src={row[label.key]}
        alt={row[label.key]}
        width="40px"
        height="40px"
        style={{ borderRadius: "50%" }}
      />
    ) : isAdmin(label.key) && row[label.key] ? (
      `${row[label.key].firstName} ${row[label.key].lastName}
        ${row[label.key].email} ${row[label.key].phone}`
    ) : isUser(label.key) ? (
      `${row[label.key].firstName} ${row[label.key].lastName}
        ${row[label.key].email} ${row[label.key].phone}`
    ) : isCar(label.key) && typeof row[label.key] === "object" ? (
      `${row[label.key].brand} ${row[label.key].model} 
        ${row[label.key].name}`
    ) : isDeal(label.key) ? (
      `${row[label.key].brand}, ${row[label.key].model}, 
        ${row[label.key].trimLevel}, $${row[label.key].price},
        ${row[label.key].miles} miles`
    ) : isActive(label.key) ? (
      row[label.key] ? (
        "Active"
      ) : (
        "Blocked"
      )
    ) : !isEmail(label.key) && row[label.key] && row[label.key].length > 0 ? (
      capitalize(row[label.key])
    ) : (
      row[label.key]
    )}
  </td>
);

const Table = ({ data, labels, caption, actions }) => {
  if (!data) return null;

  const mapTableRow = (row, index) => {
    return (
      <tr key={row?._id || index}>
        {labels.map((label, index) => (
          <TableBodyCell key={label.key} row={row} label={label} index={index} />
        ))}
        {actions && (
          <td className="table-cell">
            {actions.map((action) => (
              <ActionItem key={action.type} index={index} row={row} action={action} />
            ))}
          </td>
        )}
      </tr>
    );
  };

  return (
    <table className="table table-striped">
      <thead>
        <tr>
          {labels.map((label, index) => (
            <TableHeaderCell key={label.key} label={label} index={index} />
          ))}
          {actions && <th>Actions</th>}
        </tr>
      </thead>
      <tbody>{data.map(mapTableRow)}</tbody>
    </table>
  );
};

Table.propTypes = {
  caption: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape({})),
  labels: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      key: PropTypes.string,
    })
  ).isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      action: PropTypes.func,
      type: PropTypes.string,
      table: PropTypes.string,
    })
  ),
};
TableHeaderCell.propTypes = {
  label: PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};
TableBodyCell.propTypes = {
  row: PropTypes.shape({}).isRequired,
  label: PropTypes.shape({
    label: PropTypes.string,
    key: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};
ActionItem.propTypes = {
  row: PropTypes.shape({}).isRequired,
  action: PropTypes.shape({
    action: PropTypes.func,
    type: PropTypes.string,
    table: PropTypes.string,
  }).isRequired,
  index: PropTypes.number,
};

export default Table;
