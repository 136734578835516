import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import InputGroup from "../common/InputGroup";
import ImageSelector from "../common/ImageSelector";
import SelectListGroup from "../common/SelectListGroup";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import { createEditCarModalStyles } from "../../constants/modalStyles";
import { colors, rangeTypes } from "../../constants";

class CreateEditCarModal extends React.Component {
  state = {
    data: this.props.data,
    errors: {},
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  removePhotos = () => {
    const data = { ...this.state.data };
    data.rangePhotoUrls = [];
    this.setState({ data });
  };

  openDropzone = () => {
    this.dropzone.open();
  };

  uploadPhotos = (files) => {
    const data = { ...this.state.data };
    data.files = files;

    data.files.forEach((file) => {
      let reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.onload = (r) => {
        data.rangePhotoUrls.push(r.target.result);
        this.setState({ data });
      };
      reader.readAsDataURL(file);
    });

    this.setState({ data });
  };

  previousImage = () => {
    const data = { ...this.state.data };
    if (data.photoIndex > 0) {
      data.photoIndex -= 1;
    }
    this.setState({ data });
  };

  nextImage = () => {
    const data = { ...this.state.data };
    if (data.photoIndex < data.rangePhotoUrls.length - 1) {
      data.photoIndex += 1;
    }
    this.setState({ data });
  };

  isDataValid = () => {
    let { data } = this.state;
    let errors = {};
    let formIsValid = true;

    //Brand
    if (!data["brand"]) {
      formIsValid = false;
      errors["brand"] = "Cannot be empty";
    }
    //Model
    if (!data["model"]) {
      formIsValid = false;
      errors["model"] = "Cannot be empty";
    }
    //Range Type
    if (!data["rangeType"]) {
      formIsValid = false;
      errors["rangeType"] = "Cannot be empty";
    }
    //Trim Level
    if (!data["trimLevel"]) {
      formIsValid = false;
      errors["trimLevel"] = "Cannot be empty";
    }
    //Int Color
    if (!data["intColor"] || data["intColor"].name === "") {
      formIsValid = false;
      errors["intColor"] = "Cannot be empty";
    }
    //Ext Color
    if (!data["extColor"] || data["extColor"].name === "") {
      formIsValid = false;
      errors["extColor"] = "Cannot be empty";
    }
    //Equipment
    if (!data["equipment"]) {
      formIsValid = false;
      errors["equipment"] = "Cannot be empty";
    }
    //Files
    if (!data["files"] && data["rangePhotoUrls"].length === 0) {
      formIsValid = false;
      errors["files"] = "Cannot be empty";
    }

    this.setState({ errors });
    return formIsValid;
  };

  submit = (e) => {
    e.preventDefault();
    let data = { ...this.state.data };
    data.intColor =
      typeof data.intColor === "object"
        ? data.intColor
        : colors.find((color) => color.name === data.intColor);
    data.extColor =
      typeof data.extColor === "object"
        ? data.extColor
        : colors.find((color) => color.name === data.extColor);

    if (this.isDataValid()) {
      this.props.createEditCar(data);
    }
  };

  render() {
    const { data, errors } = this.state;
    const { isOpen, edit, brands, models } = this.props;

    if (!data) return null;

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        style={createEditCarModalStyles}
        contentLabel="Create Car Modal"
      >
        <h3 className="display-4 text-center">{edit ? "Edit" : "Create"} Car</h3>
        <hr />
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-md-12">
              <ImageSelector
                imgSrc={
                  data && data.rangePhotoUrls[data.photoIndex]
                    ? data.rangePhotoUrls[data.photoIndex]
                    : ""
                }
                previousImage={this.previousImage}
                nextImage={this.nextImage}
                removePhotos={this.removePhotos}
                handleChange={this.onChange}
                openDropzone={this.openDropzone}
                error={errors.files}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Brand Name</label>
              <SelectListGroup
                name="brand"
                value={data.brand}
                onChange={this.onChange}
                options={brands}
                error={errors.brand}
              />
            </div>
            <div className="col-md-4">
              <label>Model Name</label>
              <SelectListGroup
                name="model"
                value={data.model}
                onChange={this.onChange}
                options={models.filter((model) => model.brand === data.brand)}
                error={errors.model}
              />
            </div>
            <div className="col-md-4">
              <label>Type</label>
              <SelectListGroup
                name="rangeType"
                value={data.rangeType}
                onChange={this.onChange}
                options={rangeTypes}
                error={errors.rangeType}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Trim Level</label>
              <InputGroup
                name="trimLevel"
                type="text"
                value={data.trimLevel}
                onChange={this.onChange}
                error={errors.trimLevel}
              />
            </div>
            <div className="col-md-4">
              <label>Interior Color</label>
              <SelectListGroup
                name="intColor"
                value={data.intColor.name}
                onChange={this.onChange}
                options={colors}
                error={errors.intColor}
              />
            </div>
            <div className="col-md-4">
              <label>Exterior Color</label>
              <SelectListGroup
                name="extColor"
                value={data.extColor.name}
                onChange={this.onChange}
                options={colors}
                error={errors.extColor}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Equipment</label>
              <SelectListGroup
                name="equipment"
                value={data.equipment}
                onChange={this.onChange}
                options={[{ name: "full" }, { name: "basic" }]}
                error={errors.equipment}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Range Description</label>
              <TextAreaFieldGroup
                name="rangeDescription"
                value={data.rangeDescription}
                onChange={this.onChange}
              />
            </div>
          </div>
          <input type="submit" value="Save" className="btn btn-info" />
          <Dropzone
            ref={(input) => {
              this.dropzone = input;
            }}
            onDrop={(files) => this.uploadPhotos(files)}
            multiple={true}
            accept="image/*"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </form>
      </Modal>
    );
  }
}

CreateEditCarModal.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  createEditCar: PropTypes.func,
  closeModal: PropTypes.func,
};

export default CreateEditCarModal;
