import {
  GET_BRANDS,
  CREATE_BRAND,
  DELETE_BRAND,
  BRAND_LOADING
} from "../actions/types";

const initialState = {
  brands: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case BRAND_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_BRANDS:
      return {
        ...state,
        brands: action.payload,
        loading: false
      };
    case CREATE_BRAND:
      return {
        ...state,
        brands: [action.payload, ...state.brands],
        loading: false
      };
    case DELETE_BRAND:
      return {
        ...state,
        brands: state.brands.filter(post => post._id !== action.payload),
        loading: false
      };
    default:
      return state;
  }
};
