import { GET_TRIMS, CREATE_TRIM, DELETE_TRIM, TRIM_LOADING } from "../actions/types";

const initialState = {
  trims: null,
  loading: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TRIM_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_TRIMS:
      return {
        ...state,
        trims: action.payload,
        loading: false,
      };
    case CREATE_TRIM:
      return {
        ...state,
        trims: [action.payload, ...state.trims],
        loading: false,
      };
    case DELETE_TRIM:
      return {
        ...state,
        trims: state.trims.filter((post) => post._id !== action.payload),
        loading: false,
      };
    default:
      return state;
  }
};
