import axios from "axios";
import { ROOT_URL } from "../constants";
import { GET_ERRORS, CLEAR_ERRORS } from "./types";

import { getInquiries } from "./inquiryActions";
import { getDealInquiries } from "./dealInquiryActions";

// Create inquiry message
export const createInquiryMessage = (inquiryId, message) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post(ROOT_URL + "/v1/adminApi/inquiryMessages", { inquiryId, message })
    .then(async (res) => {
      dispatch(getInquiries());
      dispatch(getDealInquiries());
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

// Mark inquiry messages as read
export const markInquiryMessagesRead = (inquiryId) => (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post(ROOT_URL + "/v1/adminApi/inquiryMessages/markRead", { inquiryId })
    .then(async (res) => dispatch({ type: CLEAR_ERRORS }))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};
