import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import InputGroup from "../common/InputGroup";
import { resetPasswordModalStyles } from "../../constants/modalStyles";

class ResetPasswordModal extends React.Component {
  state = {
    password: "",
    oldPassword: ""
  };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submit = e => {
    e.preventDefault();
    let data = {};
    data.oldPassword = this.state.oldPassword;
    data.password = this.state.password;

    this.props.resetPassword(data);
  };

  render() {
    const { isOpen, closeModal, errors } = this.props;

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={resetPasswordModalStyles}
        contentLabel="Reset Password Modal"
      >
        <h3 className="display-4 text-center">Reset Password</h3>
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-md-6">
              <InputGroup
                name="oldPassword"
                placeholder="Old password"
                type="password"
                value={this.state.oldPassword}
                onChange={this.onChange}
                error={
                  errors.error && errors.error.field === "oldPassword"
                    ? errors.error.message
                    : ""
                }
              />
            </div>
            <div className="col-md-6">
              <InputGroup
                name="password"
                placeholder="Password"
                type="password"
                value={this.state.password}
                onChange={this.onChange}
              />
            </div>
          </div>
          <input type="submit" value="Reset" className="btn btn-info" />
        </form>
      </Modal>
    );
  }
}

ResetPasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  errors: PropTypes.object,
  closeModal: PropTypes.func,
  resetPassword: PropTypes.func
};

export default ResetPasswordModal;
