export const GET_ERRORS = "GET_ERRORS";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const SET_CURRENT_ADMIN = "SET_CURRENT_ADMIN";
export const SET_AUTHENTICATED_ADMIN = "SET_AUTHENTICATED_ADMIN";
export const GET_ADMIN = "GET_ADMIN";
export const GET_ADMINS = "GET_ADMINS";
export const CREATE_ADMIN = "CREATE_ADMIN";
export const ADMIN_LOADING = "ADMIN_LOADING";
export const GET_USER = "GET_USER";
export const GET_USERS = "GET_USERS";
export const GET_BRAND = "GET_BRAND";
export const GET_BRANDS = "GET_BRANDS";
export const CREATE_BRAND = "CREATE_BRAND";
export const DELETE_BRAND = "DELETE_BRAND";
export const GET_MODEL = "GET_MODEL";
export const GET_MODELS = "GET_MODELS";
export const CREATE_MODEL = "CREATE_MODEL";
export const DELETE_MODEL = "DELETE_MODEL";
export const GET_CAR = "GET_CAR";
export const GET_CARS = "GET_CARS";
export const CREATE_CAR = "CREATE_CAR";
export const DELETE_CAR = "DELETE_CAR";
export const GET_TRIM = "GET_TRIM";
export const GET_TRIMS = "GET_TRIMS";
export const CREATE_TRIM = "CREATE_TRIM";
export const DELETE_TRIM = "DELETE_TRIM";
export const GET_DEAL = "GET_DEAL";
export const GET_DEALS = "GET_DEALS";
export const CREATE_DEAL = "CREATE_DEAL";
export const DELETE_DEAL = "DELETE_DEAL";
export const USER_LOADING = "USER_LOADING";
export const BRAND_LOADING = "BRAND_LOADING";
export const MODEL_LOADING = "MODEL_LOADING";
export const CAR_LOADING = "CAR_LOADING";
export const TRIM_LOADING = "TRIM_LOADING";
export const DEAL_LOADING = "DEAL_LOADING";
export const DEALINQUIRY_LOADING = "DEALINQUIRY_LOADING";
export const GET_INQUIRIES = "GET_INQUIRIES";
export const GET_DEALINQUIRIES = "GET_DEALINQUIRIES";
export const INQUIRY_LOADING = "INQUIRY_LOADING";
export const GET_ADMIN_ACTIVITIES = "GET_ADMIN_ACTIVITIES";
export const ADMIN_ACTIVITIY_LOADING = "ADMIN_ACTIVITIY_LOADING";
