const resetPasswordModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    minWidth: "45%",
    maxWidth: "60%",
    maxHeight: "80%",
    overflowY: "hidden",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

const forgotPasswordModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    minWidth: "45%",
    maxWidth: "60%",
    maxHeight: "60%",
    overflowY: "hidden",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

const createEditAdminModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    minWidth: "45%",
    maxWidth: "60%",
    minHeight: "55%",
    maxHeight: "65%",
    overflowY: "hidden",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

const createEditBrandModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    minWidth: "45%",
    maxWidth: "55%",
    minHeight: "55%",
    maxHeight: "75%",
    overflowY: "hidden",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

const createEditModelModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    minWidth: "55%",
    maxWidth: "65%",
    minHeight: "55%",
    maxHeight: "75%",
    overflowY: "hidden",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

const createEditCarModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    minWidth: "55%",
    maxWidth: "65%",
    minHeight: "55%",
    maxHeight: "75%",
    overflowY: "scroll",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

const createEditTrimModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    minWidth: "55%",
    maxWidth: "65%",
    minHeight: "55%",
    maxHeight: "75%",
    overflowY: "scroll",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

const createEditDealModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    minWidth: "55%",
    maxWidth: "65%",
    minHeight: "55%",
    maxHeight: "75%",
    overflowY: "scroll",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

const editInquiryModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    minWidth: "40%",
    maxWidth: "50%",
    minHeight: "55%",
    maxHeight: "80%",
    overflowY: "scroll",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

const editDealInquiryModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    minWidth: "40%",
    maxWidth: "50%",
    minHeight: "55%",
    maxHeight: "80%",
    overflowY: "hidden",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

const deleteModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    minWidth: "40%",
    maxWidth: "50%",
    maxHeight: "80%",
    overflowY: "hidden",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

const chatModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    width: "35%",
    minHeight: "50%",
    maxHeight: "60%",
    overflowY: "hidden",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

const editUserModalStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    padding: 10,
    background: "#F3F3F3",
    minWidth: "55%",
    maxWidth: "65%",
    minHeight: "55%",
    maxHeight: "75%",
    overflowY: "scroll",
    overflowX: "hidden",
    transform: "translate(-50%, -50%)",
  },
};

module.exports = {
  forgotPasswordModalStyles,
  resetPasswordModalStyles,
  createEditAdminModalStyles,
  createEditBrandModalStyles,
  createEditModelModalStyles,
  createEditCarModalStyles,
  createEditTrimModalStyles,
  createEditDealModalStyles,
  editInquiryModalStyles,
  editDealInquiryModalStyles,
  chatModalStyles,
  deleteModalStyles,
  editUserModalStyles,
};
