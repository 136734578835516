import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Redirect } from "react-router";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setAuthenticatedAdmin, logoutAdmin } from "./actions/authActions";
import { getAdmin } from "./actions/adminActions";
import { Provider } from "react-redux";
import store from "./store";

import PrivateRoute from "./components/common/PrivateRoute";

import Cars from "./components/cars/Cars";
import Trims from "./components/trims/Trims";
import Login from "./components/auth/Login";
import Users from "./components/users/Users";
import Deals from "./components/deals/Deals";
import Admins from "./components/admins/Admins";
import Brands from "./components/brands/Brands";
import Models from "./components/models/Models";
import Inquiries from "./components/inquiries/Inquiries";
import DealInquiries from "./components/dealInquiries/DealInquiries";
import AdminActivities from "./components/adminActivities/AdminActivities";
import NotFound from "./components/not-found/NotFound";

import "./App.css";
require("dotenv").config();

// Check for token
if (localStorage.jwtToken) {
  // Set auth token header auth
  setAuthToken(localStorage.jwtToken);
  // Decode token and get admin info and expiration
  const decoded = jwt_decode(localStorage.jwtToken);
  // Set admin and isAuthenticated
  store.dispatch(setAuthenticatedAdmin());
  store.dispatch(getAdmin());
  // Check for expired token
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutAdmin());
    // Redirect to login
    window.location.href = "/login";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router>
          <div className="App">
            <header className="App-header">
              <Switch>
                <Route exact path="/">
                  <Redirect to="/login" />
                </Route>
                <Route exact path="/login" component={Login} />
                <PrivateRoute exact path="/admins" component={Admins} />
                <PrivateRoute exact path="/users" component={Users} />
                <PrivateRoute exact path="/brands" component={Brands} />
                <PrivateRoute exact path="/models" component={Models} />
                <PrivateRoute exact path="/cars" component={Cars} />
                <PrivateRoute exact path="/trims" component={Trims} />
                <PrivateRoute exact path="/deals" component={Deals} />
                <PrivateRoute exact path="/inquiries" component={Inquiries} />
                <PrivateRoute exact path="/adminActivities" component={AdminActivities} />
                <PrivateRoute exact path="/dealInquiries" component={DealInquiries} />
                <Route path="*" component={NotFound} />
              </Switch>
            </header>
          </div>
        </Router>
      </Provider>
    );
  }
}

export default App;
