import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import InputGroup from "../common/InputGroup";
import SelectListGroup from "../common/SelectListGroup";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import { editInquiryModalStyles } from "../../constants/modalStyles";
import { inquiryStatuses } from "../../constants";

class EditInquiryModal extends React.Component {
  state = {
    data: this.props.data,
    modifiedCar: this.props.data.modifiedCar || this.props.data.trim,
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  };

  onCarChange = (e) => {
    const { name, value } = e.target;
    const modifiedCar = { ...this.state.modifiedCar };
    modifiedCar[name] = value;
    this.setState({ modifiedCar });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  removeFiles = () => {
    const data = { ...this.state.data };
    data.adminFiles = [];
    this.setState({ data });
  };

  openDropzone = () => {
    this.dropzone.open();
  };

  uploadFiles = (files) => {
    const data = { ...this.state.data };
    data.files = files;

    data.files.forEach((file) => {
      let reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.onload = (r) => {
        data.adminFiles.push(r.target.result);
        this.setState({ data });
      };
      reader.readAsDataURL(file);
    });

    this.setState({ data });
  };

  submit = (e) => {
    e.preventDefault();
    let data = { ...this.state.data };
    let modifiedCar = { ...this.state.modifiedCar };

    this.props.updateInquiry(data, modifiedCar);
  };

  createDealFromInquiry = (e) => {
    e.preventDefault();
    let data = { ...this.state.data };
    let modifiedCar = { ...this.state.modifiedCar };

    let deal = {
      brand: modifiedCar.brand,
      model: modifiedCar.model,
      trim: modifiedCar.name,
      type: modifiedCar.type,
      photoUrls: modifiedCar.photoUrls,
      intColor: data.intColor,
      extColor: data.extColor,
      price: data.price,
      miles: data.miles,
      MSRP: data.MSRP,
      moneyDown: data.moneyDown,
      leaseTerms: data.leaseTerms,
    };

    this.props.createDealFromInquiry(deal);
  };

  render() {
    const { data, modifiedCar } = this.state;
    const { isOpen } = this.props;

    if (!data) return null;

    const adminFileThumbnails = data.adminFiles.map((file, index) => {
      return (
        <p key={index} className="file-link">
          <a href={file}>
            {file
              .slice(0, file.indexOf("?"))
              .split("/")
              .pop()}
          </a>
        </p>
      );
    });
    const userFileThumbnails = data.userFiles.map((file, index) => {
      return (
        <p key={index} className="file-link">
          <a href={file}>
            {file
              .slice(0, file.indexOf("?"))
              .split("/")
              .pop()}
          </a>
        </p>
      );
    });

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        style={editInquiryModalStyles}
        contentLabel="Uppdate Inquiry Modal"
      >
        <h3 className="display-4 text-center">Update Inquiry</h3>
        <hr />
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-md-6">
              <label>Admin Files</label>
              {adminFileThumbnails}
            </div>
            <div className="col-md-6">
              <label>User Files</label>
              {userFileThumbnails}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <button type="button" className="btn btn-info" onClick={this.openDropzone}>
                Add Files
              </button>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-4">
              <label>Return MSRP</label>
              <InputGroup name="MSRP" type="number" value={data.MSRP} onChange={this.onChange} />
            </div>
            <div className="col-md-3">
              <label>Status</label>
              <SelectListGroup
                name="status"
                value={data.status}
                onChange={this.onChange}
                options={inquiryStatuses}
              />
            </div>
            <div className="col-md-4">
              <label>Price</label>
              <InputGroup name="price" type="number" value={data.price} onChange={this.onChange} />
            </div>
            <div className="col-md-4">
              <label>Money Down</label>
              <InputGroup
                name="moneyDown"
                type="number"
                value={data.moneyDown}
                onChange={this.onChange}
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-3">
              <label>Lease Terms</label>
              <InputGroup
                name="leaseTerms"
                type="number"
                value={data.leaseTerms || 0}
                onChange={this.onChange}
              />
            </div>
            <div className="col-md-3">
              <label>Miles</label>
              <InputGroup name="miles" type="number" value={data.miles} onChange={this.onChange} />
            </div>
            <div className="col-md-3">
              <label>Brand</label>
              <InputGroup
                name="brand"
                type="text"
                value={modifiedCar.brand}
                onChange={this.onCarChange}
              />
            </div>
            <div className="col-md-3">
              <label>Model</label>
              <InputGroup
                name="model"
                type="text"
                value={modifiedCar.model}
                onChange={this.onCarChange}
              />
            </div>
            <div className="col-md-3">
              <label>Trim</label>
              <InputGroup
                name="name"
                type="text"
                value={modifiedCar.name}
                onChange={this.onCarChange}
              />
            </div>
            <div className="col-md-3">
              <label>Interior Color</label>
              <InputGroup
                name="intColor"
                type="text"
                value={data.intColor}
                onChange={this.onCarChange}
              />
            </div>
            <div className="col-md-3">
              <label>Exterior Color</label>
              <InputGroup
                name="extColor"
                type="text"
                value={data.extColor}
                onChange={this.onCarChange}
              />
            </div>
            <div className="col-md-6">
              <label>Additional Comment</label>
              <TextAreaFieldGroup
                name="additionalComment"
                value={data.additionalComment}
                disabled={true}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-1" style={{ marginRight: "10px" }}>
              <input type="submit" value="Save" className="btn btn-info" />
            </div>
            <div className="col-md-1">
              <button className="btn btn-info" onClick={this.createDealFromInquiry}>
                Create Deal
              </button>
            </div>
          </div>
          <Dropzone
            ref={(input) => {
              this.dropzone = input;
            }}
            onDrop={(files) => this.uploadFiles(files)}
            multiple={true}
            accept="image/*,.pdf"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </form>
      </Modal>
    );
  }
}

EditInquiryModal.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  updateInquiry: PropTypes.func,
  createDealFromInquiry: PropTypes.func,
  closeModal: PropTypes.func,
};

export default EditInquiryModal;
