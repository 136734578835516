import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

// Tabs
const tabs = [
  { label: "Admins", url: "/admins", src: "adminWhite" },
  { label: "Users", url: "/users", src: "userWhite" },
  { label: "Brands", url: "/brands", src: "brandWhite" },
  { label: "Models", url: "/models", src: "modelWhite" },
  // { label: "Cars", url: "/cars", src: "carWhite" },
  { label: "Trims", url: "/trims", src: "carWhite" },
  { label: "Deals", url: "/deals", src: "dealWhite" },
  { label: "Inquiries", url: "/inquiries", src: "inquiryWhite" },
  { label: "Deal Inquiries", url: "/dealInquiries", src: "dealInquiryWhite" },
  { label: "Admin Activity Log", url: "/adminActivities", src: "activitiesLog" },
];

class Sidebar extends React.Component {
  render() {
    return (
      <div className="sidebar-nav">
        <i
          style={{ float: "right", marginRight: "10px" }}
          className="fa fa-times"
          onClick={this.props.toggleSidebar}
          title="Hide"
        />
        {tabs &&
          tabs.map((tab) => {
            return (
              <Link
                key={tab.label}
                to={`${tab.url}`}
                className="truncate"
                style={{ display: "flex" }}
              >
                <div
                  className={
                    "sidebar-tab" + (window.location.pathname === tab.url ? " active" : "")
                  }
                >
                  <p>{tab.label}</p>
                </div>
              </Link>
            );
          })}
      </div>
    );
  }
}

Sidebar.propTypes = {
  isOpen: PropTypes.bool,
};

export default Sidebar;
