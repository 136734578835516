import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import SelectListGroup from "../common/SelectListGroup";
import { editDealInquiryModalStyles } from "../../constants/modalStyles";
import { inquiryStatuses } from "../../constants";

class EditDealInquiryModal extends React.Component {
  state = {
    data: this.props.data
  };

  onChange = e => {
    let { name, value } = e.target;
    const data = { ...this.state.data };
    value = name === "markedForFeed" ? e.target.checked : value;
    data[name] = value;
    this.setState({ data });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  removeFiles = () => {
    const data = { ...this.state.data };
    data.adminFiles = [];
    this.setState({ data });
  };

  openDropzone = () => {
    this.dropzone.open();
  };

  uploadFiles = files => {
    const data = { ...this.state.data };
    data.files = files;

    data.files.forEach(file => {
      let reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.onload = r => {
        data.adminFiles.push(r.target.result);
        this.setState({ data });
      };
      reader.readAsDataURL(file);
    });

    this.setState({ data });
  };

  submit = e => {
    e.preventDefault();
    let data = { ...this.state.data };
    this.props.updateDealInquiry(data);
  };

  render() {
    const { data } = this.state;
    const { isOpen } = this.props;

    if (!data) return null;

    const adminFileThumbnails = data.adminFiles.map((file, index) => {
      return (
        <p key={index} className="file-link">
          <a href={file}>
            {file
              .slice(0, file.indexOf("?"))
              .split("/")
              .pop()}
          </a>
        </p>
      );
    });
    const userFileThumbnails = data.userFiles.map((file, index) => {
      return (
        <p key={index} className="file-link">
          <a href={file}>
            {file
              .slice(0, file.indexOf("?"))
              .split("/")
              .pop()}
          </a>
        </p>
      );
    });

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        style={editDealInquiryModalStyles}
        contentLabel="Uppdate Deal Inquiry Modal"
      >
        <h3 className="display-4 text-center">Update Deal Inquiry</h3>
        <hr />
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-md-6">
              <label>Admin Files</label>
              {adminFileThumbnails}
            </div>
            <div className="col-md-6">
              <label>User Files</label>
              {userFileThumbnails}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <button type="button" className="btn btn-info" onClick={this.openDropzone}>
                Add Files
              </button>
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-md-4">
              <label>Status</label>
              <SelectListGroup
                name="status"
                value={data.status}
                onChange={this.onChange}
                options={inquiryStatuses}
              />
            </div>
            <div className="col-md-6">
              <label style={{ paddingTop: "35px" }}>
                Send to activity feed:{" "}
                <input
                  name="markedForFeed"
                  type="checkbox"
                  checked={data.markedForFeed}
                  onChange={this.onChange}
                />
              </label>
            </div>
          </div>
          <input type="submit" value="Save" className="btn btn-info" />
          <Dropzone
            ref={input => {
              this.dropzone = input;
            }}
            onDrop={files => this.uploadFiles(files)}
            multiple={true}
            accept="image/*,.pdf"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </form>
      </Modal>
    );
  }
}

EditDealInquiryModal.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  updateDealInquiry: PropTypes.func,
  closeModal: PropTypes.func
};

export default EditDealInquiryModal;
