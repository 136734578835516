import axios from "axios";
import { ROOT_URL } from "../constants";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_ADMINS,
  CREATE_ADMIN,
  ADMIN_LOADING
} from "./types";
import { logoutAdmin, setCurrentAdmin } from "./authActions";

// Get admins
export const getAdmins = () => dispatch => {
  dispatch({ type: ADMIN_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .get(ROOT_URL + "/v1/adminApi/admins")
    .then(async res => {
      dispatch({ type: GET_ADMINS, payload: res.data.admins });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Get own admin
export const getAdmin = () => dispatch => {
  dispatch({ type: CLEAR_ERRORS });
  axios
    .get(ROOT_URL + "/v1/adminApi/admins/me")
    .then(async res => {
      dispatch(setCurrentAdmin(res.data.admin));
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Create admin
export const createAdmin = adminData => dispatch => {
  dispatch({ type: ADMIN_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post(ROOT_URL + "/v1/adminApi/admins", adminData)
    .then(res => {
      dispatch({ type: CREATE_ADMIN, payload: res.data.admin });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Reset admin password
export const resetPassword = adminData => dispatch => {
  axios
    .patch(ROOT_URL + "/v1/adminApi/admins/me/resetPassword", adminData)
    .then(async res => {
      await localStorage.clear();
      dispatch({ type: CLEAR_ERRORS });
      dispatch(logoutAdmin());
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Edit admin
export const editAdmin = adminData => dispatch => {
  axios
    .patch(ROOT_URL + "/v1/adminApi/admins/me", adminData)
    .then(res => {
      dispatch({ type: CLEAR_ERRORS });
      dispatch(setCurrentAdmin(res.data.admin));
      dispatch(getAdmins());
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};
