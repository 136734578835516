import axios from "axios";
import { ROOT_URL } from "../constants";
import {
  GET_ERRORS,
  GET_DEALINQUIRIES,
  DEALINQUIRY_LOADING,
  CLEAR_ERRORS
} from "./types";

// Get deal inquiries
export const getDealInquiries = () => dispatch => {
  dispatch({ type: DEALINQUIRY_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .get(ROOT_URL + "/v1/adminApi/dealInquiries")
    .then(async res => {
      dispatch({
        type: GET_DEALINQUIRIES,
        payload: res.data.dealInquiries
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Update deal inquiry
export const updateDealInquiry = (id, updateData) => dispatch => {
  axios
    .patch(ROOT_URL + "/v1/adminApi/dealInquiries/" + id, updateData)
    .then(async res => dispatch(getDealInquiries()))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};
