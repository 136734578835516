import axios from "axios";
import { ROOT_URL } from "../constants";
import {
  GET_ERRORS,
  GET_INQUIRIES,
  INQUIRY_LOADING,
  CLEAR_ERRORS
} from "./types";

// Get inquiries
export const getInquiries = () => dispatch => {
  dispatch({ type: INQUIRY_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .get(ROOT_URL + "/v1/adminApi/inquiries")
    .then(async res => {
      dispatch({ type: GET_INQUIRIES, payload: res.data.inquiries });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Update inquiry
export const updateInquiry = (id, updateData) => dispatch => {
  axios
    .patch(ROOT_URL + "/v1/adminApi/inquiries/" + id, updateData)
    .then(async res => dispatch(getInquiries()))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};
