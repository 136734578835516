import React, { Component } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { logoutAdmin } from "../../actions/authActions";
import { resetPassword, editAdmin } from "../../actions/adminActions";
import ResetPasswordModal from "../modals/ResetPasswordModal";
import EditAdmindModal from "../modals/CreateEditAdminModal";

// import logo from "../../images/logo.png";

class Navbar extends Component {
  state = {
    errors: {},
    isDropdownOpen: false,
    editAdminModal: false,
    resetPasswordModal: false
  };

  static getDerivedStateFromProps(nextProps, prevState) {
    let newProps = {};
    newProps.errors = nextProps.errors;
    if (nextProps.errors) {
      return newProps;
    } else return null;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.errors !== this.props.errors) {
      if (this.props.errors.error === "Bad Request") {
        this.setState({ editAdminModal: true });
      }
    }
  }

  resetInitialState = () => {
    this.setState({
      errors: {},
      isDropdownOpen: false,
      editAdminModal: false,
      resetPasswordModal: false
    });
  };

  toggleDropdown = () =>
    this.setState({ isDropdownOpen: !this.state.isDropdownOpen });

  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutAdmin();
  };

  openResetPasswordModal = () => {
    this.setState({ resetPasswordModal: true });
  };

  closeResetPasswordModal = () => {
    this.resetInitialState();
  };

  resetPassword = data => {
    this.props.resetPassword(data);
  };

  openEditAdminModal = () => {
    this.setState({ editAdminModal: true });
  };

  closeEditAdminModal = () => {
    this.resetInitialState();
  };

  editAdmin = data => {
    this.props.editAdmin(data);

    this.setState({ editAdminModal: false });
  };

  render() {
    const { isAuthenticated, admin } = this.props.auth;
    const { resetPasswordModal, editAdminModal, errors } = this.state;
    const menuClass = `dropdown-menu${
      this.state.isDropdownOpen ? " show" : ""
    }`;

    const authLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item dropdown" onClick={this.toggleDropdown}>
          <a
            className="nav-link dropdown-toggle"
            href="# "
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {admin.firstName} {admin.lastName}
          </a>
          <div className={menuClass} aria-labelledby="navbarDropdown">
            <a
              className="dropdown-item"
              href="# "
              onClick={this.openEditAdminModal}
            >
              Edit
            </a>
            <a
              className="dropdown-item"
              href="# "
              onClick={this.openResetPasswordModal}
            >
              Reset password
            </a>
            <div className="dropdown-divider"></div>
            <a className="dropdown-item" onClick={this.onLogoutClick} href="# ">
              Logout
            </a>
          </div>
        </li>
      </ul>
    );

    const guestLinks = (
      <ul className="navbar-nav ml-auto">
        <li className="nav-item">
          <Link className="nav-link" to="/login">
            Login
          </Link>
        </li>
      </ul>
    );

    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark mb-4">
          <div className="container">
            <Link className="navbar-brand" to="/">
              {/* <img
                                src={logo}
                                alt="Logo"
                                width="50px"
                                height="30px"
                            /> */}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#mobile-nav"
            >
              <span className="navbar-toggler-icon"></span>
            </button>

            <div className="collapse navbar-collapse" id="mobile-nav">
              {isAuthenticated ? authLinks : guestLinks}
            </div>
          </div>
        </nav>
        {resetPasswordModal && (
          <ResetPasswordModal
            isOpen={resetPasswordModal}
            closeModal={this.closeResetPasswordModal}
            resetPassword={this.resetPassword}
            errors={errors}
          />
        )}
        {editAdminModal && (
          <EditAdmindModal
            edit={true}
            isOpen={editAdminModal}
            key={admin._id}
            data={admin}
            closeModal={this.closeEditAdminModal}
            createEditAdmin={this.editAdmin}
            errors={errors}
          />
        )}
      </React.Fragment>
    );
  }
}

Navbar.propTypes = {
  editAdmin: PropTypes.func.isRequired,
  logoutAdmin: PropTypes.func.isRequired,
  resetPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, {
  logoutAdmin,
  resetPassword,
  editAdmin
})(Navbar);
