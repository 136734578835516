let host = process.env.REACT_APP_DEVELOPMENT_HOST;
if (process.env.NODE_ENV === "production") {
  host = process.env.REACT_APP_PRODUCTION_HOST; // make it REACT_APP_HOST="..." npm start later to be read as process.env.REACT_APP_HOST, needs to be done in the deploy script as well
}

const ROOT_URL = host;
const ITEMS_PER_PAGE = 5;

const admin_template = {
  firstName: "",
  lastName: "",
  password: "",
  email: "",
  phone: "",
  role: "",
};

const brand_template = {
  name: "",
  photoUrl: "",
};

const model_template = {
  name: "",
  brand: "",
  photoUrl: "",
};

const car_template = {
  brand: "",
  model: "",
  trimLevel: "",
  rangeType: "",
  rangeDescription: "",
  equipment: "",
  intColor: { name: "", hexCode: "" },
  extColor: { name: "", hexCode: "" },
  rangePhotoUrls: [],
};

const trim_template = {
  brand: "",
  model: "",
  name: "",
  type: "",
  description: "",
  equipment: "",
  photoUrls: [],
};

const qualifyingForm_template = {
  brand: "",
  model: "",
  trim: "",
  scpecialFeatures: "",
  colors: "",
  payment: "",
  currentCar: "",
  currentPayment: "",
  loyalty: "",
  terms: "",
  location: "",
  notes: "",
};

const deal_template = {
  brand: "",
  model: "",
  trim: "",
  disclaimer: "",
  type: "",
  price: "",
  year: "",
  moneyDown: "",
  MSRP: "",
  miles: "",
  leaseTerms: "",
  equipment: "",
  intColor: { name: "", hexCode: "" },
  extColor: { name: "", hexCode: "" },
  options: [],
  photoUrls: [],
};

const colors = [
  { name: "black", hexCode: "#000000" },
  { name: "white", hexCode: "#ffffff" },
  { name: "grey", hexCode: "#808080" },
  { name: "red", hexCode: "#ff0000" },
  { name: "green", hexCode: "#008000" },
  { name: "blue", hexCode: "#0000ff" },
  { name: "yellow", hexCode: "#ffff00" },
  { name: "orange", hexCode: "#ffa500" },
  { name: "violet", hexCode: "#800080" },
  { name: "brown", hexCode: "#a52a2a" },
  { name: "gold", hexCode: "#edda74" },
  { name: "silver", hexCode: "#c0c0c0" },
  { name: "beige", hexCode: "#f5f5dc" },
];

const intColors = [
  { name: "dark", hexCode: "#000000" },
  { name: "light", hexCode: "#ffffff" },
  { name: "flexible", hexCode: "" },
];

const rangeTypes = [
  { value: "minivan", name: "Minivan" },
  { value: "suv", name: "SUV" },
  { value: "sedan", name: "Sedan" },
  { value: "convertible", name: "Convertible" },
  { value: "coupe", name: "Coupe" },
  { value: "crossover", name: "Crossover" },
  { value: "small", name: "Small Car" },
  { value: "truck", name: "Truck" },
];

const inquiryStatuses = [{ name: "pending" }, { name: "accepted" }, { name: "closed" }];

module.exports = {
  ROOT_URL,
  ITEMS_PER_PAGE,
  admin_template,
  brand_template,
  model_template,
  car_template,
  trim_template,
  deal_template,
  qualifyingForm_template,
  rangeTypes,
  inquiryStatuses,
  colors,
  intColors,
};
