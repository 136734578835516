export const userLabels = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "Email", key: "email" },
  { label: "Phone Number", key: "phone" },
  { label: "Status", key: "isActive" },
  { label: "Date Created", key: "createdAt" },
];

export const adminLabels = [
  { label: "First Name", key: "firstName" },
  { label: "Last Name", key: "lastName" },
  { label: "E-mail", key: "email" },
  { label: "Phone", key: "phone" },
];

export const brandLabels = [
  { label: "Logo", key: "photoUrl" },
  { label: "Name", key: "name" },
  { label: "Date Created", key: "createdAt" },
];

export const modelLabels = [
  { label: "Photo", key: "photoUrl" },
  { label: "Brand Name", key: "brand" },
  { label: "Name", key: "name" },
  { label: "Date Created", key: "createdAt" },
];

export const carLabels = [
  { label: "Brand Name", key: "brand" },
  { label: "Model Name", key: "model" },
  { label: "Trim Level", key: "trimLevel" },
  { label: "Date Created", key: "createdAt" },
];

export const trimLabels = [
  { label: "Brand Name", key: "brand" },
  { label: "Model Name", key: "model" },
  { label: "Trim", key: "name" },
  { label: "Date Created", key: "createdAt" },
];

export const dealLabels = [
  { label: "Brand Name", key: "brand" },
  { label: "Model Name", key: "model" },
  { label: "Trim", key: "trim" },
  { label: "Type", key: "type" },
  { label: "Date Created", key: "createdAt" },
];

export const inquiryLabels = [
  { label: "User Info", key: "user" },
  { label: "Admin Info", key: "admin" },
  { label: "Trim", key: "trim" },
  { label: "Miles", key: "miles" },
  { label: "Lease Terms", key: "leaseTerms" },
  { label: "Zip Code", key: "userZipCode" },
  { label: "Status", key: "status" },
  { label: "Date Created", key: "createdAt" },
];

export const dealInquiryLabels = [
  { label: "User Info", key: "user" },
  { label: "Admin Info", key: "admin" },
  { label: "Deal Info", key: "deal" },
  { label: "Zip Code", key: "userZipCode" },
  { label: "Status", key: "status" },
  { label: "Date Created", key: "createdAt" },
];

export const adminActivityLabels = [
  { label: "Admin", key: "adminEmail" },
  { label: "Document", key: "documentId" },
  { label: "Desription", key: "description" },
  { label: "Date Created", key: "createdAt" },
];
