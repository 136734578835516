import {
  GET_ADMIN_ACTIVITIES,
  ADMIN_ACTIVITIY_LOADING
} from "../actions/types";

const initialState = {
  adminActivities: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_ACTIVITIY_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_ADMIN_ACTIVITIES:
      return {
        ...state,
        adminActivities: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
