import React from "react";
import PropTypes from "prop-types";

import leftArrow from "../../static/icons/left-arrow.svg";
import rightArrow from "../../static/icons/right-arrow.svg";
import photoCamera from "../../static/icons/photo-camera.svg";
import noPreview from "../../static/images/no-preview.jpg";

const ImageSelector = ({
  imgSrc,
  previousImage,
  nextImage,
  removePhotos,
  openDropzone,
  error
}) => {
  return (
    <React.Fragment>
      <div className="image-selector">
        <img alt="" src={imgSrc ? imgSrc : noPreview} className="img" />
        <img
          src={leftArrow}
          alt=""
          title="Previous image"
          className="left-arrow"
          onClick={previousImage}
        />
        <img
          src={rightArrow}
          alt=""
          title="Next image"
          className="right-arrow"
          onClick={nextImage}
        />
        {removePhotos && (
          <span className="img-remove" onClick={removePhotos}>
            {" X "}
          </span>
        )}
        <div
          className="img-addons center"
          title="Add Photo"
          onClick={openDropzone}
        >
          <img alt="" className="icon-img" src={photoCamera} />
        </div>
        {error && <small style={{ color: "red" }}>{error}</small>}
      </div>
    </React.Fragment>
  );
};

ImageSelector.propTypes = {
  error: PropTypes.string,
  imgSrc: PropTypes.string,
  inputValue: PropTypes.string,
  previousImage: PropTypes.func,
  nextImage: PropTypes.func,
  removePhotos: PropTypes.func,
  openDropzone: PropTypes.func,
  handleChange: PropTypes.func
};

export default ImageSelector;
