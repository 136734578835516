import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loginAdmin, forgotPassword } from "../../actions/authActions";
import TextFieldGroup from "../common/TextFieldGroup";
import ForgotPasswordModal from "../modals/ForgotPasswordModal";

class Login extends Component {
  state = {
    email: "",
    password: "",
    forgotPasswordModal: false,
    errors: {}
  };

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push("/admins");
    }
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let newProps = {};
    newProps.auth = {};
    newProps.auth.isAuthenticated = nextProps.auth.isAuthenticated;
    newProps.errors = nextProps.errors;
    if (nextProps.errors || nextProps.auth.isAuthenticated) {
      return newProps;
    } else return null;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.auth.isAuthenticated !== this.props.auth.isAuthenticated) {
      this.props.history.push("/admins");
    }

    if (prevProps.errors !== prevState.errors) {
      this.setState({ errors: prevProps.errors });
    }
  }

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  onSubmit = e => {
    e.preventDefault();

    const adminData = {
      email: this.state.email,
      password: this.state.password
    };

    this.props.loginAdmin(adminData);
  };

  openForgotPasswordModal = () => {
    this.setState({ forgotPasswordModal: true });
  };

  closeForgotPasswordModal = () => {
    this.setState({ forgotPasswordModal: false, email: "" });
  };

  forgotPassword = data => {
    this.props.forgotPassword(data);
    this.setState({ forgotPasswordModal: false, email: "" });
  };

  render() {
    const { errors, forgotPasswordModal } = this.state;

    return (
      <div className="login">
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto">
              <h1 className="display-4 text-center">Log In</h1>
              <form onSubmit={this.onSubmit}>
                <TextFieldGroup
                  name="email"
                  placeholder="Email Address"
                  type="email"
                  value={this.state.email}
                  onChange={this.onChange}
                  error={
                    errors.error && errors.error.field === "email"
                      ? errors.error.message
                      : ""
                  }
                />
                <TextFieldGroup
                  name="password"
                  placeholder="Password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={
                    errors.error && errors.error.field === "password"
                      ? errors.error.message
                      : ""
                  }
                />
                <input type="submit" className="btn btn-info btn-block mt-4" />
                <a
                  href="# "
                  onClick={this.openForgotPasswordModal}
                  className="nav-link"
                  role="button"
                >
                  Forgot Password
                </a>
              </form>
            </div>
          </div>
        </div>
        <ForgotPasswordModal
          isOpen={forgotPasswordModal}
          closeModal={this.closeForgotPasswordModal}
          forgotPassword={this.forgotPassword}
        />
      </div>
    );
  }
}

Login.propTypes = {
  loginAdmin: PropTypes.func.isRequired,
  forgotPassword: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  auth: state.auth,
  errors: state.errors
});

export default connect(mapStateToProps, { loginAdmin, forgotPassword })(Login);
