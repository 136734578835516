import isEmpty from "../validation/is-empty";

import { SET_CURRENT_ADMIN, SET_AUTHENTICATED_ADMIN } from "../actions/types";

const initialState = {
  isAuthenticated: false,
  admin: {}
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_CURRENT_ADMIN:
      return {
        ...state,
        isAuthenticated: !isEmpty(action.payload),
        admin: action.payload
      };
    case SET_AUTHENTICATED_ADMIN:
      return {
        ...state,
        isAuthenticated: true
      };
    default:
      return state;
  }
};
