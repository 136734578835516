import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

import Table from "../common/Table";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import Spinner from "../common/Spinner";
import InputGroup from "../common/InputGroup";
import DeleteModal from "../modals/DeleteModal";
import CreateEditTrimModal from "../modals/CreateEditTrimModal";
import { getTrims, createTrim, updateTrim, deleteTrim } from "../../actions/trimActions";
import { getBrands } from "../../actions/brandActions";
import { getModels } from "../../actions/modelActions";

import { trimLabels } from "../../constants/tableData";
import { prepareTrimFormData } from "../../utils/helpers";
import { ITEMS_PER_PAGE, trim_template } from "../../constants";

class Trims extends Component {
  state = {
    edit: false,
    isOpen: true,
    search: "",
    activePage: 1,
    activeTrim: null,
    deleteModal: false,
    createEditTrimModal: false,
  };

  componentDidMount() {
    this.props.getTrims();
    this.props.getModels();
    this.props.getBrands();
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  changePage = (activePage) => {
    this.setState({ activePage });
  };

  toggleSidebar = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  openCreateEditTrimModal = (row) => {
    trim_template.rangePhotoUrls = [];
    const activeTrim = row ? row : trim_template;
    activeTrim.photoIndex = 0;
    const edit = Boolean(row);

    this.setState({ createEditTrimModal: true, activeTrim, edit });
  };

  closeCreateEditTrimModal = () => {
    this.setState({ createEditTrimModal: false });
  };

  createEditTrim = (data) => {
    const trim = prepareTrimFormData(data);

    if (this.state.edit) {
      this.props.updateTrim(data._id, trim);
    } else {
      this.props.createTrim(trim);
    }

    this.setState({ createEditTrimModal: false });
  };

  openDeleteModal = (activeTrim) => {
    this.setState({ deleteModal: true, activeTrim });
  };

  closeDeleteModal = () => {
    this.setState({ deleteModal: false, activeTrim: null });
  };

  deleteTrim = () => {
    const { activeTrim } = this.state;
    this.props.deleteTrim(activeTrim._id);
    this.closeDeleteModal();
  };

  render() {
    const {
      edit,
      isOpen,
      search,
      activeTrim,
      activePage,
      deleteModal,
      createEditTrimModal,
    } = this.state;
    const { trims, loading } = this.props.trims;
    const { brands } = this.props.brands;
    const { models } = this.props.models;

    if (!trims) return null;

    let sidebar = isOpen ? (
      <Sidebar isOpen={isOpen} toggleSidebar={this.toggleSidebar} />
    ) : (
      <i
        className="fas fa-bars"
        style={{ marginLeft: "10px", cursor: "pointer" }}
        onClick={this.toggleSidebar}
        title="Show"
      />
    );

    let filteredTrims = trims;
    if (search.length > 2) {
      filteredTrims = trims.filter((trim) => {
        return trim.brand.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
    }

    return (
      <div className="trims">
        <Navbar />
        {sidebar}
        <div className="container-fluid fixed-right">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="display-4 text-center">Trims</h1>
              <hr />
            </div>
            <div className="col-lg-9">
              <button
                className="btn btn-info"
                onClick={() => this.openCreateEditTrimModal(null)}
                style={{ marginBottom: "5px" }}
              >
                Add Trim
              </button>
            </div>
            <div className="col-lg-3">
              <InputGroup
                name="search"
                type="text"
                placeholder="Search"
                value={search}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {loading || trims === null ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  <Table
                    labels={trimLabels}
                    data={filteredTrims.slice(
                      (activePage - 1) * ITEMS_PER_PAGE,
                      activePage * ITEMS_PER_PAGE
                    )}
                    actions={[
                      {
                        type: "edit",
                        action: this.openCreateEditTrimModal,
                      },
                      {
                        type: "delete",
                        action: this.openDeleteModal,
                      },
                    ]}
                  />
                  <Pagination
                    activePage={activePage}
                    totalItemsCount={trims.length}
                    onChange={this.changePage}
                    pageRangeDisplayed={5}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </React.Fragment>
              )}
              {createEditTrimModal && (
                <CreateEditTrimModal
                  edit={edit}
                  data={activeTrim}
                  brands={brands}
                  models={models}
                  isOpen={createEditTrimModal}
                  closeModal={this.closeCreateEditTrimModal}
                  createEditTrim={this.createEditTrim}
                />
              )}
              {deleteModal && (
                <DeleteModal
                  title="Delete Trim"
                  description="Are you sure you want to delete this trim?"
                  isOpen={deleteModal}
                  closeModal={this.closeDeleteModal}
                  deleteAction={this.deleteTrim}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Trims.propTypes = {
  getTrims: PropTypes.func.isRequired,
  getBrands: PropTypes.func.isRequired,
  getModels: PropTypes.func.isRequired,
  createTrim: PropTypes.func.isRequired,
  updateTrim: PropTypes.func.isRequired,
  deleteTrim: PropTypes.func.isRequired,
  trims: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  trims: state.trims,
  brands: state.brands,
  models: state.models,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getTrims,
  getBrands,
  getModels,
  createTrim,
  updateTrim,
  deleteTrim,
})(Trims);
