import axios from "axios";
import { ROOT_URL } from "../constants";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_BRANDS,
  CREATE_BRAND,
  DELETE_BRAND,
  BRAND_LOADING
} from "./types";

// Get brands
export const getBrands = () => dispatch => {
  dispatch({ type: BRAND_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .get(ROOT_URL + "/v1/adminApi/brands")
    .then(async res => {
      dispatch({ type: GET_BRANDS, payload: res.data.brands });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Create brand
export const createBrand = (brandData, history) => dispatch => {
  dispatch({ type: BRAND_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post(ROOT_URL + "/v1/adminApi/brands", brandData)
    .then(res => {
      dispatch({ type: CREATE_BRAND, payload: res.data.brand });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Update brand
export const updateBrand = (id, updateData) => dispatch => {
  axios
    .patch(ROOT_URL + "/v1/adminApi/brands/" + id, updateData)
    .then(async res => dispatch(getBrands()))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Delete brand
export const deleteBrand = id => dispatch => {
  dispatch({ type: BRAND_LOADING });
  axios
    .delete(ROOT_URL + "/v1/adminApi/brands/" + id)
    .then(async res => dispatch({ type: DELETE_BRAND, payload: id }))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};
