import axios from "axios";
import { ROOT_URL } from "../constants";
import setAuthToken from "../utils/setAuthToken";

import { getAdmin } from "./adminActions";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  SET_CURRENT_ADMIN,
  SET_AUTHENTICATED_ADMIN
} from "./types";

// Register Admin
export const registerAdmin = (adminData, history) => dispatch => {
  axios
    .post(ROOT_URL + "/v1/adminApi/admins/register", adminData)
    .then(res => {
      history.push("/login");
    })
    .catch(err =>
      dispatch({
        type: GET_ERRORS,
        payload: err.response.data
      })
    );
};

// Login Admin - get token
export const loginAdmin = adminData => dispatch => {
  axios
    .post(ROOT_URL + "/v1/adminApi/admins/login", adminData)
    .then(res => {
      // Save token to local storage
      const { token } = res.data;
      localStorage.setItem("jwtToken", token);
      // Set token to header
      setAuthToken(token);
      // Clear errors
      dispatch({ type: CLEAR_ERRORS });
      // Set current admin
      dispatch(setAuthenticatedAdmin());
      dispatch(getAdmin());
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Forgot Password
export const forgotPassword = adminData => dispatch => {
  axios
    .post(ROOT_URL + "/v1/adminApi/admins/forgotPassword", adminData)
    .then(res => {})
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Set logged in admin
export const setCurrentAdmin = decoded => {
  return {
    type: SET_CURRENT_ADMIN,
    payload: decoded
  };
};

// Set authenticated admin
export const setAuthenticatedAdmin = () => {
  return {
    type: SET_AUTHENTICATED_ADMIN
  };
};

// Log admin out
export const logoutAdmin = () => dispatch => {
  // Remove token from local storage
  localStorage.removeItem("jwtToken");
  // Remove auth header for future requests
  setAuthToken(false);
  // Set current admin to empty object and isAuthenticated to false
  dispatch(setCurrentAdmin({}));
};
