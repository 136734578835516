import {
  GET_DEALS,
  CREATE_DEAL,
  DELETE_DEAL,
  DEAL_LOADING
} from "../actions/types";

const initialState = {
  deals: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DEAL_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_DEALS:
      return {
        ...state,
        deals: action.payload,
        loading: false
      };
    case CREATE_DEAL:
      return {
        ...state,
        deals: [action.payload, ...state.deals],
        loading: false
      };
    case DELETE_DEAL:
      return {
        ...state,
        deals: state.deals.filter(post => post._id !== action.payload),
        loading: false
      };
    default:
      return state;
  }
};
