import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import InputGroup from "../common/InputGroup";
import OptionInputs from "../common/OptionInputs";
import ImageSelector from "../common/ImageSelector";
import SelectListGroup from "../common/SelectListGroup";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import { createEditDealModalStyles } from "../../constants/modalStyles";
import { colors, intColors, rangeTypes } from "../../constants";

class CreateEditDealModal extends React.Component {
  state = {
    data: this.props.data,
    errors: {},
  };

  onChange = (e) => {
    const { name, value, classList } = e.target;
    const data = { ...this.state.data };

    if (classList.contains("option")) {
      let options = [...this.state.data.options];
      options[e.target.dataset.id] = value;
      data.options = options;
    } else {
      data[name] = value;
    }

    this.setState({ data });
  };

  onChangeCheckbox = (e) => {
    const data = { ...this.state.data };
    data.isActivityFeed = !data.isActivityFeed;

    this.setState({ data });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  removePhotos = () => {
    const data = { ...this.state.data };
    data.photoUrls = [];
    this.setState({ data });
  };

  openDropzone = () => {
    this.dropzone.open();
  };

  uploadPhotos = (files) => {
    const data = { ...this.state.data };
    data.files = files;

    data.files.forEach((file) => {
      let reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.onload = (r) => {
        data.photoUrls.push(r.target.result);
        this.setState({ data });
      };
      reader.readAsDataURL(file);
    });

    this.setState({ data });
  };

  previousImage = () => {
    const data = { ...this.state.data };
    if (data.photoIndex > 0) {
      data.photoIndex -= 1;
    }
    this.setState({ data });
  };

  nextImage = () => {
    const data = { ...this.state.data };
    if (data.photoIndex < data.photoUrls.length - 1) {
      data.photoIndex += 1;
    }
    this.setState({ data });
  };

  addOption = (e) => {
    e.preventDefault();
    const data = { ...this.state.data };
    data.options = [...data.options, ""];
    this.setState({ data });
  };

  isDataValid = () => {
    let { data } = this.state;
    let errors = {};
    let formIsValid = true;

    //Brand
    if (!data["brand"]) {
      formIsValid = false;
      errors["brand"] = "Cannot be empty";
    }
    //Model
    if (!data["model"]) {
      formIsValid = false;
      errors["model"] = "Cannot be empty";
    }
    //Trim
    if (!data["trim"]) {
      formIsValid = false;
      errors["trim"] = "Cannot be empty";
    }
    //Type
    if (!data["type"]) {
      formIsValid = false;
      errors["type"] = "Cannot be empty";
    }
    //Price
    if (!data["price"]) {
      formIsValid = false;
      errors["price"] = "Cannot be empty";
    }
    //Miles
    if (!data["miles"]) {
      formIsValid = false;
      errors["miles"] = "Cannot be empty";
    }
    //Lease Terms
    if (!data["leaseTerms"]) {
      formIsValid = false;
      errors["leaseTerms"] = "Cannot be empty";
    }
    //Money Down
    if (!data["moneyDown"]) {
      formIsValid = false;
      errors["moneyDown"] = "Cannot be empty";
    }
    //MSRP
    if (!data["MSRP"]) {
      formIsValid = false;
      errors["MSRP"] = "Cannot be empty";
    }
    //Int Color
    if (!data["intColor"] || data["intColor"].name === "") {
      formIsValid = false;
      errors["intColor"] = "Cannot be empty";
    }
    //Ext Color
    if (!data["extColor"] || data["extColor"].name === "") {
      formIsValid = false;
      errors["extColor"] = "Cannot be empty";
    }
    //Files
    if (!data["files"] && data["photoUrls"].length === 0) {
      formIsValid = false;
      errors["files"] = "Cannot be empty";
    }

    this.setState({ errors });
    return formIsValid;
  };

  submit = (e) => {
    e.preventDefault();
    let data = { ...this.state.data };
    data.intColor =
      typeof data.intColor === "object"
        ? data.intColor
        : intColors.find((color) => color.name === data.intColor);
    data.extColor = colors.find((color) =>
      typeof data.extColor === "object" ? data.extColor : color.name === data.extColor
    );

    if (!data.isActivityFeed) {
      data.isActivityFeed = false; // fix undefined error on backend
    }

    if (this.isDataValid(data)) {
      this.props.createEditDeal(data);
    }
  };

  render() {
    const { data, errors } = this.state;
    const { isOpen, edit, brands, models } = this.props;

    if (!data) return null;

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        style={createEditDealModalStyles}
        contentLabel="Create Deal Modal"
      >
        <h3 className="display-4 text-center">{edit ? "Edit" : "Create"} Deal</h3>
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-md-12">
              <ImageSelector
                imgSrc={
                  data && data.photoUrls[data.photoIndex] ? data.photoUrls[data.photoIndex] : ""
                }
                previousImage={this.previousImage}
                nextImage={this.nextImage}
                removePhotos={this.removePhotos}
                handleChange={this.onChange}
                openDropzone={this.openDropzone}
                error={errors.files}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Brand Name</label>
              <SelectListGroup
                name="brand"
                value={data.brand}
                onChange={this.onChange}
                options={brands}
                error={errors.brand}
              />
            </div>
            <div className="col-md-4">
              <label>Model Name</label>
              <SelectListGroup
                name="model"
                value={data.model}
                onChange={this.onChange}
                options={models.filter((model) => model.brand === data.brand)}
                error={errors.model}
              />
            </div>
            <div className="col-md-4">
              <label>Trim</label>
              <InputGroup
                name="trim"
                type="text"
                value={data.trim}
                onChange={this.onChange}
                error={errors.trim}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Type</label>
              <SelectListGroup
                name="type"
                value={data.type}
                onChange={this.onChange}
                options={rangeTypes}
                error={errors.type}
              />
            </div>
            <div className="col-md-4">
              <label>Peice</label>
              <InputGroup
                name="price"
                type="number"
                value={data.price}
                onChange={this.onChange}
                error={errors.price}
              />
            </div>
            <div className="col-md-4">
              <label>Money Down</label>
              <InputGroup
                name="moneyDown"
                type="number"
                value={data.moneyDown}
                onChange={this.onChange}
                error={errors.moneyDown}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>MSRP</label>
              <InputGroup
                name="MSRP"
                type="number"
                value={data.MSRP}
                onChange={this.onChange}
                error={errors.MSRP}
              />
            </div>
            <div className="col-md-4">
              <label>Miles</label>
              <InputGroup
                name="miles"
                type="number"
                value={data.miles}
                onChange={this.onChange}
                error={errors.miles}
              />
            </div>
            <div className="col-md-4">
              <label>Lease Terms</label>
              <InputGroup
                name="leaseTerms"
                type="number"
                value={data.leaseTerms}
                onChange={this.onChange}
                error={errors.leaseTerms}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Year</label>
              <InputGroup
                name="year"
                type="number"
                value={data.year}
                onChange={this.onChange}
                error={errors.year}
              />
            </div>
            <div className="col-md-4">
              <label>Interior Color</label>
              <SelectListGroup
                name="intColor"
                value={data.intColor.name}
                onChange={this.onChange}
                options={intColors}
                error={errors.intColor}
              />
            </div>
            <div className="col-md-4">
              <label>Exterior Color</label>
              <SelectListGroup
                name="extColor"
                value={data.extColor.name}
                onChange={this.onChange}
                options={colors}
                error={errors.extColor}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Disclaimer</label>
              <TextAreaFieldGroup
                name="disclaimer"
                value={data.disclaimer}
                onChange={this.onChange}
                error={errors.disclaimer}
              />
            </div>
            <div className="col-md-6">
              <label style={{ paddingTop: "35px" }}>
                Send to activity feed:{" "}
                <input
                  name="isActivityFeed"
                  type="checkbox"
                  checked={data.isActivityFeed}
                  onChange={this.onChangeCheckbox}
                />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3">
              <button
                className="btn btn-sm btn-info"
                onClick={this.addOption}
                style={{ marginBottom: "5px" }}
              >
                + Add option
              </button>
            </div>
          </div>
          <OptionInputs options={data.options} onChange={this.onChange} />
          <input type="submit" value="Save" className="btn btn-info" />
          <Dropzone
            ref={(input) => {
              this.dropzone = input;
            }}
            onDrop={(files) => this.uploadPhotos(files)}
            multiple={true}
            accept="image/*"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </form>
      </Modal>
    );
  }
}

CreateEditDealModal.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  createEditDeal: PropTypes.func,
  closeModal: PropTypes.func,
};

export default CreateEditDealModal;
