import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

import Table from "../common/Table";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import Spinner from "../common/Spinner";
import InputGroup from "../common/InputGroup";
import DeleteModal from "../modals/DeleteModal";
import EditUserModal from "../modals/EditUserModal";
import { getUsers, updateUser } from "../../actions/userActions";
import { getInquiries } from "../../actions/inquiryActions";

import { userLabels } from "../../constants/tableData";
import { ITEMS_PER_PAGE } from "../../constants";

class Users extends Component {
  state = {
    isOpen: true,
    deleteModal: false,
    editUserModal: false,
    activeUser: null,
    activeInuiries: [],
    activePage: 1,
    search: "",
  };

  componentDidMount() {
    this.props.getUsers();
    this.props.getInquiries();
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  changePage = (activePage) => {
    this.setState({ activePage });
  };

  toggleSidebar = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  openEditUserModal = (row) => {
    const activeUser = row;
    const { inquiries } = this.props.inquiries;
    let activeInuiries = inquiries.filter((inquiry) => {
      return inquiry.user._id.toLowerCase().indexOf(activeUser._id.toLowerCase()) !== -1;
    });

    this.setState({ editUserModal: true, activeUser, activeInuiries });
  };

  closeEditUserModal = () => {
    this.setState({ editUserModal: false });
  };

  openDeleteModal = (activeUser) => {
    this.setState({ deleteModal: true, activeUser });
  };

  closeDeleteModal = () => {
    this.setState({ deleteModal: false, activeUser: null });
  };

  changeUserActiveStatus = (row) => {
    this.props.updateUser(row._id, { isActive: !row.isActive });
  };

  updateUser = (id, data) => {
    this.props.updateUser(id, data);
    this.setState({ editUserModal: false });
  };

  render() {
    const {
      isOpen,
      activePage,
      activeUser,
      search,
      editUserModal,
      deleteModal,
      activeInuiries,
    } = this.state;
    const { users, loading } = this.props.users;

    if (!users) return null;

    let sidebar = isOpen ? (
      <Sidebar isOpen={isOpen} toggleSidebar={this.toggleSidebar} />
    ) : (
      <i
        className="fas fa-bars"
        style={{ marginLeft: "10px", cursor: "pointer" }}
        onClick={this.toggleSidebar}
        title="Show"
      />
    );

    let filteredUsers = users;
    if (search.length > 2) {
      filteredUsers = users.filter((user) => {
        return user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
    }

    return (
      <div className="users">
        <Navbar />
        {sidebar}
        <div className="container-fluid fixed-right">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="display-4 text-center">Users</h1>
              <hr />
            </div>
            <div className="col-lg-3 offset-lg-9">
              <InputGroup
                name="search"
                type="text"
                placeholder="Search"
                value={search}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {loading || users === null ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  <Table
                    labels={userLabels}
                    data={filteredUsers.slice(
                      (activePage - 1) * ITEMS_PER_PAGE,
                      activePage * ITEMS_PER_PAGE
                    )}
                    actions={[
                      {
                        type: "edit",
                        action: this.openEditUserModal,
                      },
                      {
                        type: "editStatus",
                        action: this.changeUserActiveStatus,
                      },
                    ]}
                  />
                  <Pagination
                    activePage={activePage}
                    totalItemsCount={users.length}
                    onChange={this.changePage}
                    pageRangeDisplayed={5}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </React.Fragment>
              )}
              {editUserModal && (
                <EditUserModal
                  data={activeInuiries}
                  user={activeUser}
                  isOpen={editUserModal}
                  updateUser={this.updateUser}
                  closeModal={this.closeEditUserModal}
                />
              )}
              {deleteModal && (
                <DeleteModal
                  title="Delete Trim"
                  description="Are you sure you want to delete this trim?"
                  isOpen={deleteModal}
                  closeModal={this.closeDeleteModal}
                  deleteAction={this.deleteTrim}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Users.propTypes = {
  getInquiries: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  updateUser: PropTypes.func.isRequired,
  users: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  users: state.users,
  inquiries: state.inquiries,
  errors: state.errors,
});

export default connect(mapStateToProps, { getInquiries, getUsers, updateUser })(Users);
