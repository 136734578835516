import React, { Component } from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Pagination from "react-js-pagination";

import Table from "../common/Table";
import InputGroup from "../common/InputGroup";
import { inquiryLabels } from "../../constants/tableData";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import { editUserModalStyles } from "../../constants/modalStyles";
import { ITEMS_PER_PAGE, inquiryStatuses, qualifyingForm_template } from "../../constants";
import { capitalize } from "../../utils/helpers";

class EditUserModal extends Component {
  state = {
    data: this.props.data,
    user: this.props.user,
    qualifyingForm: this.props.user.qualifyingForm || qualifyingForm_template,
    activePage: 1,
    activeTab: "pending",
  };

  onQualifyingFormChange = (e) => {
    const { name, value } = e.target;
    const qualifyingForm = { ...this.state.qualifyingForm };
    qualifyingForm[name] = value;
    this.setState({ qualifyingForm });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  changePage = (activePage) => {
    this.setState({ activePage });
  };

  changeTab = (activeTab) => () => {
    this.setState({ activeTab });
    this.changePage(1);
  };

  submit = (e) => {
    e.preventDefault();
    let user = { ...this.state.user };
    let qualifyingForm = { ...this.state.qualifyingForm };

    this.props.updateUser(user._id, { qualifyingForm });
  };

  render() {
    const { data, qualifyingForm, activeTab, activePage } = this.state;
    const { isOpen } = this.props;

    if (!data) return null;

    const filteredInquiries = data.filter((inquiry) => inquiry.status === activeTab);

    const tabs = inquiryStatuses.map((inquiry) => (
      <li
        key={inquiry.name}
        role="presentation"
        className="nav-item"
        onClick={this.changeTab(inquiry.name)}
      >
        <span className={inquiry.name === activeTab ? "nav-link active" : "nav-link"}>
          {capitalize(inquiry.name)}
        </span>
      </li>
    ));

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        style={editUserModalStyles}
        contentLabel="Create Trim Modal"
      >
        <h3 className="display-4 text-center">User Inquiries</h3>
        <hr />
        <h4>
          <ul className="nav nav-tabs">{tabs}</ul>
        </h4>
        <React.Fragment>
          <Table
            labels={inquiryLabels}
            data={filteredInquiries.slice(
              (activePage - 1) * ITEMS_PER_PAGE,
              activePage * ITEMS_PER_PAGE
            )}
          />
          <Pagination
            activePage={activePage}
            totalItemsCount={filteredInquiries.length}
            onChange={this.changePage}
            pageRangeDisplayed={5}
            itemsCountPerPage={ITEMS_PER_PAGE}
            itemClass="page-item"
            linkClass="page-link"
          />
        </React.Fragment>
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-md-4">
              <label>Brand</label>
              <InputGroup
                name="brand"
                type="text"
                value={qualifyingForm.brand}
                onChange={this.onQualifyingFormChange}
              />
            </div>
            <div className="col-md-4">
              <label>Model</label>
              <InputGroup
                name="model"
                type="text"
                value={qualifyingForm.model}
                onChange={this.onQualifyingFormChange}
              />
            </div>
            <div className="col-md-4">
              <label>Trim</label>
              <InputGroup
                name="trim"
                type="text"
                value={qualifyingForm.trim}
                onChange={this.onQualifyingFormChange}
              />
            </div>
            <div className="col-md-4">
              <label>Special Features</label>
              <InputGroup
                name="specialFeatures"
                type="text"
                value={qualifyingForm.specialFeatures}
                onChange={this.onQualifyingFormChange}
              />
            </div>
            <div className="col-md-4">
              <label>Colors</label>
              <InputGroup
                name="colors"
                type="text"
                value={qualifyingForm.colors}
                onChange={this.onQualifyingFormChange}
              />
            </div>
            <div className="col-md-4">
              <label>Payment</label>
              <InputGroup
                name="payment"
                type="text"
                value={qualifyingForm.payment}
                onChange={this.onQualifyingFormChange}
              />
            </div>
            <div className="col-md-4">
              <label>Current Car</label>
              <InputGroup
                name="currentCar"
                type="text"
                value={qualifyingForm.currentCar}
                onChange={this.onQualifyingFormChange}
              />
            </div>
            <div className="col-md-4">
              <label>Current Payment</label>
              <InputGroup
                name="currentPayment"
                type="text"
                value={qualifyingForm.currentPayment}
                onChange={this.onQualifyingFormChange}
              />
            </div>
            <div className="col-md-4">
              <label>Loyalty</label>
              <InputGroup
                name="loyalty"
                type="text"
                value={qualifyingForm.loyalty}
                onChange={this.onQualifyingFormChange}
              />
            </div>
            <div className="col-md-4">
              <label>Terms</label>
              <InputGroup
                name="terms"
                type="text"
                value={qualifyingForm.terms}
                onChange={this.onQualifyingFormChange}
              />
            </div>
            <div className="col-md-4">
              <label>Location</label>
              <InputGroup
                name="location"
                type="text"
                value={qualifyingForm.location}
                onChange={this.onQualifyingFormChange}
              />
            </div>
            <div className="col-md-5">
              <label>Notes</label>
              <TextAreaFieldGroup
                name="notes"
                value={qualifyingForm.notes}
                onChange={this.onQualifyingFormChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-1">
              <input type="submit" value="Save" className="btn btn-info" />
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

EditUserModal.propTypes = {
  data: PropTypes.array,
  isOpen: PropTypes.bool,
  updateUser: PropTypes.func,
  closeModal: PropTypes.func,
};

export default EditUserModal;
