import {
  GET_CARS,
  CREATE_CAR,
  DELETE_CAR,
  CAR_LOADING
} from "../actions/types";

const initialState = {
  cars: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CAR_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_CARS:
      return {
        ...state,
        cars: action.payload,
        loading: false
      };
    case CREATE_CAR:
      return {
        ...state,
        cars: [action.payload, ...state.cars],
        loading: false
      };
    case DELETE_CAR:
      return {
        ...state,
        cars: state.cars.filter(post => post._id !== action.payload),
        loading: false
      };
    default:
      return state;
  }
};
