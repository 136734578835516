import { GET_ADMINS, ADMIN_LOADING, CREATE_ADMIN } from "../actions/types";

const initialState = {
  admins: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_ADMINS:
      return {
        ...state,
        admins: action.payload,
        loading: false
      };
    case CREATE_ADMIN:
      return {
        ...state,
        admins: [action.payload, ...state.admins],
        loading: false
      };
    default:
      return state;
  }
};
