import axios from "axios";
import { ROOT_URL } from "../constants";
import { GET_ERRORS, GET_USERS, USER_LOADING, CLEAR_ERRORS } from "./types";

// Get users
export const getUsers = () => dispatch => {
  dispatch({ type: USER_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .get(ROOT_URL + "/v1/adminApi/users")
    .then(async res => {
      dispatch({ type: GET_USERS, payload: res.data.users });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Update user
export const updateUser = (id, updateData) => dispatch => {
  axios
    .patch(ROOT_URL + "/v1/adminApi/users/" + id, updateData)
    .then(async res => dispatch(getUsers()))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};
