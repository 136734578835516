import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

import Table from "../common/Table";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import Spinner from "../common/Spinner";
import DeleteModal from "../modals/DeleteModal";
import CreateEditDealModal from "../modals/CreateEditDealModal";
import {
  getDeals,
  createDeal,
  updateDeal,
  deleteDeal,
  promoteDeals,
} from "../../actions/dealActions";
import { getBrands } from "../../actions/brandActions";
import { getModels } from "../../actions/modelActions";

import { dealLabels } from "../../constants/tableData";
import { prepareDealFormData } from "../../utils/helpers";
import { ITEMS_PER_PAGE, deal_template } from "../../constants";

class Deals extends Component {
  state = {
    edit: false,
    isOpen: true,
    activePage: 1,
    activeDeal: null,
    deleteModal: false,
    createEditDealModal: false,
  };

  componentDidMount() {
    this.props.getDeals();
    this.props.getModels();
    this.props.getBrands();
  }

  changePage = (activePage) => {
    this.setState({ activePage });
  };

  toggleSidebar = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  openCreateEditDealModal = (row) => {
    deal_template.photoUrls = [];
    const activeDeal = row ? row : deal_template;
    activeDeal.photoIndex = 0;
    const edit = Boolean(row);

    this.setState({ createEditDealModal: true, activeDeal, edit });
  };

  closeCreateEditDealModal = () => {
    this.setState({ createEditDealModal: false });
  };

  createEditDeal = (data) => {
    const deal = prepareDealFormData(data);

    if (this.state.edit) {
      this.props.updateDeal(data._id, deal);
    } else {
      this.props.createDeal(deal);
    }

    this.setState({ createEditDealModal: false });
  };

  promoteDeals = () => {
    this.props.promoteDeals();
  };

  openDeleteModal = (activeDeal) => {
    this.setState({ deleteModal: true, activeDeal });
  };

  closeDeleteModal = () => {
    this.setState({ deleteModal: false, activeDeal: null });
  };

  deleteDeal = () => {
    const { activeDeal } = this.state;
    this.props.deleteDeal(activeDeal._id);
    this.closeDeleteModal();
  };

  render() {
    const { edit, isOpen, activePage, activeDeal, deleteModal, createEditDealModal } = this.state;
    const { deals, loading } = this.props.deals;
    const { brands } = this.props.brands;
    const { models } = this.props.models;
    const { errors } = this.props;

    if (errors.error) {
      if (!alert("Something went wrong!" + errors.error.message)) {
        window.location.reload();
      }
    }

    let sidebar = isOpen ? (
      <Sidebar isOpen={isOpen} toggleSidebar={this.toggleSidebar} />
    ) : (
      <i
        className="fas fa-bars"
        style={{ marginLeft: "10px", cursor: "pointer" }}
        onClick={this.toggleSidebar}
        title="Show"
      />
    );

    return (
      <div className="deals">
        <Navbar />
        {sidebar}
        <div className="container-fluid fixed-right">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="display-4 text-center">Deals</h1>
              <hr />
              <button
                className="btn btn-info"
                onClick={() => this.openCreateEditDealModal(null)}
                style={{ marginBottom: "5px", marginRight: "10px" }}
              >
                Add Deal
              </button>
              <button
                className="btn btn-info"
                onClick={() => this.promoteDeals(null)}
                style={{ marginBottom: "5px" }}
              >
                Promote Deals
              </button>
              {loading || deals === null ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  <Table
                    labels={dealLabels}
                    data={deals.slice(
                      (activePage - 1) * ITEMS_PER_PAGE,
                      activePage * ITEMS_PER_PAGE
                    )}
                    actions={[
                      {
                        type: "edit",
                        action: this.openCreateEditDealModal,
                      },
                      {
                        type: "delete",
                        action: this.openDeleteModal,
                      },
                    ]}
                  />
                  <Pagination
                    activePage={activePage}
                    totalItemsCount={deals.length}
                    onChange={this.changePage}
                    pageRangeDisplayed={5}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </React.Fragment>
              )}
              {createEditDealModal && (
                <CreateEditDealModal
                  edit={edit}
                  data={activeDeal}
                  brands={brands}
                  models={models}
                  isOpen={createEditDealModal}
                  closeModal={this.closeCreateEditDealModal}
                  createEditDeal={this.createEditDeal}
                />
              )}
              {deleteModal && (
                <DeleteModal
                  title="Delete Deal"
                  description="Are you sure you want to delete this deal?"
                  isOpen={deleteModal}
                  closeModal={this.closeDeleteModal}
                  deleteAction={this.deleteDeal}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Deals.propTypes = {
  getDeals: PropTypes.func.isRequired,
  getBrands: PropTypes.func.isRequired,
  getModels: PropTypes.func.isRequired,
  createDeal: PropTypes.func.isRequired,
  updateDeal: PropTypes.func.isRequired,
  deleteDeal: PropTypes.func.isRequired,
  promoteDeals: PropTypes.func.isRequired,
  deals: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  deals: state.deals,
  brands: state.brands,
  models: state.models,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getDeals,
  getBrands,
  getModels,
  createDeal,
  updateDeal,
  deleteDeal,
  promoteDeals,
})(Deals);
