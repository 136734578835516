import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

import Table from "../common/Table";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import Spinner from "../common/Spinner";
import ChatModal from "../modals/ChatModal";
import InputGroup from "../common/InputGroup";
import EditInquiryModal from "../modals/EditInquiryModal";
import { getInquiries, updateInquiry } from "../../actions/inquiryActions";
import { createInquiryMessage, markInquiryMessagesRead } from "../../actions/inquiryMessageActions";
import { createDealFromInquiry } from "../../actions/dealActions";

import { prepareInquiryFormData } from "../../utils/helpers";
import { inquiryLabels } from "../../constants/tableData";
import { ITEMS_PER_PAGE, inquiryStatuses } from "../../constants";
import { capitalize } from "../../utils/helpers";

class Inquiries extends Component {
  state = {
    isOpen: true,
    activePage: 1,
    activeInqury: null,
    activeTab: "pending",
    editInquiryModal: false,
    chatModal: false,
    search: "",
  };

  componentDidMount() {
    this.props.getInquiries();
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  changePage = (activePage) => {
    this.setState({ activePage });
  };

  changeTab = (activeTab) => () => {
    this.setState({ activeTab });
    this.changePage(1);
  };

  toggleSidebar = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  openEditInquiryModal = (row) => {
    this.setState({ editInquiryModal: true, activeInqury: row });
  };

  closeEditInquiryModal = () => {
    this.setState({ editInquiryModal: false });
  };

  updateInquiry = (data, modifyCar) => {
    const inquiry = prepareInquiryFormData(data, modifyCar);

    this.props.updateInquiry(data._id, inquiry);
    this.setState({ editInquiryModal: false });
  };

  createDealFromInquiry = (data) => {
    this.props.createDealFromInquiry(data);
    this.setState({ editInquiryModal: false });
  };

  openChatModal = (row) => {
    this.setState({ chatModal: true, activeInqury: row });
    this.props.markInquiryMessagesRead(row._id);
  };

  closeChatModal = () => {
    this.setState({ chatModal: false });
  };

  createInquiryMessage = (message) => {
    this.props.createInquiryMessage(this.state.activeInqury._id, message);
  };

  render() {
    const {
      isOpen,
      activeTab,
      search,
      activePage,
      activeInqury,
      editInquiryModal,
      chatModal,
    } = this.state;
    const { inquiries, loading } = this.props.inquiries;

    if (!inquiries) return null;

    let searchFilteredInquiries = inquiries;
    if (search.length > 2) {
      searchFilteredInquiries = inquiries.filter((inquiry) => {
        return inquiry.user.email.toLowerCase().indexOf(search.toLowerCase()) !== -1;
      });
    }
    const statusFilteredInquiries = searchFilteredInquiries.filter(
      (inquiry) => inquiry.status === activeTab
    );

    const tabs = inquiryStatuses.map((inquiry) => (
      <li
        key={inquiry.name}
        role="presentation"
        className="nav-item"
        onClick={this.changeTab(inquiry.name)}
      >
        <span className={inquiry.name === activeTab ? "nav-link active" : "nav-link"}>
          {capitalize(inquiry.name)}
        </span>
      </li>
    ));

    let sidebar = isOpen ? (
      <Sidebar isOpen={isOpen} toggleSidebar={this.toggleSidebar} />
    ) : (
      <i
        className="fas fa-bars"
        style={{ marginLeft: "10px", cursor: "pointer" }}
        onClick={this.toggleSidebar}
        title="Show"
      />
    );

    return (
      <div className="inquiries">
        <Navbar />
        {sidebar}
        <div className="container-fluid fixed-right">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="display-4 text-center">Inquiries</h1>
              <hr />
            </div>
            <div className="col-lg-9">
              <h4>
                <ul className="nav nav-tabs">{tabs}</ul>
              </h4>
            </div>
            <div className="col-lg-3">
              <InputGroup
                name="search"
                type="text"
                placeholder="Search"
                value={search}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {loading || inquiries === null ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  <Table
                    labels={inquiryLabels}
                    data={statusFilteredInquiries.slice(
                      (activePage - 1) * ITEMS_PER_PAGE,
                      activePage * ITEMS_PER_PAGE
                    )}
                    actions={[
                      {
                        type: "edit",
                        action: this.openEditInquiryModal,
                      },
                      {
                        type: "chat",
                        action: this.openChatModal,
                      },
                    ]}
                  />
                  <Pagination
                    activePage={activePage}
                    totalItemsCount={inquiries.length}
                    onChange={this.changePage}
                    pageRangeDisplayed={5}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </React.Fragment>
              )}
              {editInquiryModal && (
                <EditInquiryModal
                  data={activeInqury}
                  isOpen={editInquiryModal}
                  closeModal={this.closeEditInquiryModal}
                  updateInquiry={this.updateInquiry}
                  createDealFromInquiry={this.createDealFromInquiry}
                />
              )}
              {chatModal && (
                <ChatModal
                  key={activeInqury.inquiryMessages.length}
                  inquiryMessages={activeInqury.inquiryMessages}
                  isOpen={chatModal}
                  closeModal={this.closeChatModal}
                  createInquiryMessage={this.createInquiryMessage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Inquiries.propTypes = {
  createInquiryMessage: PropTypes.func.isRequired,
  markInquiryMessagesRead: PropTypes.func.isRequired,
  createDealFromInquiry: PropTypes.func.isRequired,
  getInquiries: PropTypes.func.isRequired,
  updateInquiry: PropTypes.func.isRequired,
  inquiries: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  inquiries: state.inquiries,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getInquiries,
  updateInquiry,
  createInquiryMessage,
  createDealFromInquiry,
  markInquiryMessagesRead,
})(Inquiries);
