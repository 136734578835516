import {
  GET_MODELS,
  CREATE_MODEL,
  DELETE_MODEL,
  MODEL_LOADING
} from "../actions/types";

const initialState = {
  models: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MODEL_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_MODELS:
      return {
        ...state,
        models: action.payload,
        loading: false
      };
    case CREATE_MODEL:
      return {
        ...state,
        models: [action.payload, ...state.models],
        loading: false
      };
    case DELETE_MODEL:
      return {
        ...state,
        models: state.models.filter(post => post._id !== action.payload),
        loading: false
      };
    default:
      return state;
  }
};
