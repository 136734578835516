import axios from "axios";
import { ROOT_URL } from "../constants";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_DEALS,
  CREATE_DEAL,
  DELETE_DEAL,
  DEAL_LOADING,
} from "./types";

// Get deals
export const getDeals = () => (dispatch) => {
  dispatch({ type: DEAL_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .get(ROOT_URL + "/v1/adminApi/deals")
    .then(async (res) => {
      dispatch({ type: GET_DEALS, payload: res.data.deals });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

// Create deal
export const createDeal = (dealData, history) => (dispatch) => {
  dispatch({ type: DEAL_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post(ROOT_URL + "/v1/adminApi/deals", dealData)
    .then((res) => {
      dispatch({ type: CREATE_DEAL, payload: res.data.deal });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

// Create deal from inquiry
export const createDealFromInquiry = (dealData) => (dispatch) => {
  dispatch({ type: DEAL_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post(ROOT_URL + "/v1/adminApi/deals/fromInquiry", dealData)
    .then((res) => {
      dispatch({ type: CREATE_DEAL, payload: res.data.deal });
    })
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

// Update deal
export const updateDeal = (id, updateData) => (dispatch) => {
  axios
    .patch(ROOT_URL + "/v1/adminApi/deals/" + id, updateData)
    .then(async (res) => dispatch(getDeals()))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

// Delete deal
export const deleteDeal = (id) => (dispatch) => {
  dispatch({ type: DEAL_LOADING });
  axios
    .delete(ROOT_URL + "/v1/adminApi/deals/" + id)
    .then(async (res) => dispatch({ type: DELETE_DEAL, payload: id }))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};

// Promote deals
export const promoteDeals = () => (dispatch) => {
  dispatch({ type: DEAL_LOADING });
  axios
    .post(ROOT_URL + "/v1/adminApi/deals/promote")
    .then(async (res) => dispatch(getDeals()))
    .catch((err) => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err,
      });
    });
};
