import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import InputGroup from "../common/InputGroup";
import SelectListGroup from "../common/SelectListGroup";
import { createEditAdminModalStyles } from "../../constants/modalStyles";

class EditAdminModal extends React.Component {
  state = {
    data: this.props.data,
    errors: {}
  };

  onChange = e => {
    let data = { ...this.state.data };
    data[e.target.name] = e.target.value;
    this.setState({ data });
  };

  closeModal = () => {
    this.setState({ data: this.props.data });
    this.props.closeModal();
  };

  isDataValid = () => {
    let { data } = this.state;
    let errors = {};
    let formIsValid = true;

    //First Name
    if (!data["firstName"]) {
      formIsValid = false;
      errors["firstName"] = "Cannot be empty";
    }
    //Last Name
    if (!data["lastName"]) {
      formIsValid = false;
      errors["lastName"] = "Cannot be empty";
    }
    //Email
    if (!data["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }
    //Phone
    if (!data["phone"]) {
      formIsValid = false;
      errors["phone"] = "Cannot be empty";
    }
    //Role
    if (!data["role"]) {
      formIsValid = false;
      errors["role"] = "Cannot be empty";
    }
    //Password
    if (!data["password"]) {
      formIsValid = false;
      errors["password"] = "Cannot be empty";
    }

    this.setState({ errors });
    return formIsValid;
  };

  submit = e => {
    e.preventDefault();
    if (this.isDataValid()) {
      this.props.createEditAdmin(this.state.data);
    }
  };

  render() {
    const { data, errors } = this.state;
    const { isOpen, edit } = this.props;

    if (!data) return null;

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        style={createEditAdminModalStyles}
        contentLabel="Edit Admin Modal"
      >
        <h3 className="display-4 text-center">
          {edit ? "Edit" : "Create"} Admin
        </h3>
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-md-6">
              <label>First Name</label>
              <InputGroup
                name="firstName"
                type="text"
                value={data.firstName}
                onChange={this.onChange}
                error={errors.firstName}
              />
            </div>
            <div className="col-md-6">
              <label>Last Name</label>
              <InputGroup
                name="lastName"
                type="text"
                value={data.lastName}
                onChange={this.onChange}
                error={errors.lastName}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Email</label>
              <InputGroup
                name="email"
                type="text"
                value={data.email}
                onChange={this.onChange}
                error={errors.email}
              />
            </div>
            <div className="col-md-6">
              <label>Phone</label>
              <InputGroup
                name="phone"
                type="text"
                value={data.phone}
                onChange={this.onChange}
                error={errors.phone}
              />
            </div>
          </div>
          {!edit && (
            <div className="row">
              <div className="col-md-6">
                <label>Role</label>
                <SelectListGroup
                  name="role"
                  value={data.role}
                  onChange={this.onChange}
                  options={[{ name: "super" }, { name: "regular" }]}
                  error={errors.role}
                />
              </div>
              <div className="col-md-6">
                <label>Password</label>
                <InputGroup
                  name="password"
                  type="password"
                  value={this.state.password}
                  onChange={this.onChange}
                  error={errors.password}
                />
              </div>
            </div>
          )}
          <input type="submit" value="Save" className="btn btn-info" />
        </form>
      </Modal>
    );
  }
}

EditAdminModal.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  edit: PropTypes.bool,
  createEditAdmin: PropTypes.func,
  closeModal: PropTypes.func,
  errors: PropTypes.object
};

export default EditAdminModal;
