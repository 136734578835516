import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import { deleteModalStyles } from "../../constants/modalStyles";

class DeleteModal extends React.Component {
  render() {
    const { title, isOpen, closeModal, description, deleteAction } = this.props;

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={deleteModalStyles}
        contentLabel="Delete Modal"
      >
        <h3 className="display-4 text-center">{title}</h3>
        <hr />
        <div style={{ margin: "auto", width: "50%" }}>
          <p style={{ fontSize: 16, marginBottom: 30 }}>{description}</p>

          <button
            className="btn btn-success"
            onClick={deleteAction}
            style={{
              fontSize: 16
            }}
          >
            Confirm
          </button>
          <button
            className="btn btn-danger"
            onClick={closeModal}
            style={{
              fontSize: 16,
              position: "absolute",
              right: "25%"
            }}
          >
            Cancel
          </button>
        </div>
      </Modal>
    );
  }
}

DeleteModal.propTypes = {
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  description: PropTypes.string,
  closeModal: PropTypes.func,
  deleteAction: PropTypes.func
};

export default DeleteModal;
