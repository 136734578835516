import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import InputGroup from "../common/InputGroup";
import { forgotPasswordModalStyles } from "../../constants/modalStyles";

class ForgotPasswordModal extends React.Component {
  state = { email: "" };

  onChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  submit = e => {
    e.preventDefault();
    this.props.forgotPassword(this.state);

    this.setState({ email: "" });
  };

  render() {
    const { isOpen, closeModal } = this.props;

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={closeModal}
        style={forgotPasswordModalStyles}
        contentLabel="Forgot Password Modal"
      >
        <h3 className="display-4 text-center">Forgot Password</h3>
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-md-12">
              <InputGroup
                name="email"
                type="text"
                placeholder="Email"
                value={this.state.email}
                onChange={this.onChange}
              />
            </div>
          </div>
          <input type="submit" value="Send Email" className="btn btn-info" />
        </form>
      </Modal>
    );
  }
}

ForgotPasswordModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  forgotPassword: PropTypes.func
};

export default ForgotPasswordModal;
