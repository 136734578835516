import { combineReducers } from "redux";
import authReducer from "./authReducer";
import errorReducer from "./errorReducer";
import adminsReducer from "./adminsReducer";
import usersReducer from "./usersReducer";
import brandsReducer from "./brandsReducer";
import modelsReducer from "./modelsReducer";
import carsReducer from "./carsReducer";
import trimsReducer from "./trimsReducer";
import dealsReducer from "./dealsReducer";
import inquiriesReducer from "./inquiriesReducer";
import dealInquiriesReducer from "./dealInquiriesReducer";
import adminActivitiesReducer from "./adminActivitiesReducer";

export default combineReducers({
  auth: authReducer,
  errors: errorReducer,
  admins: adminsReducer,
  users: usersReducer,
  brands: brandsReducer,
  models: modelsReducer,
  cars: carsReducer,
  trims: trimsReducer,
  deals: dealsReducer,
  inquiries: inquiriesReducer,
  dealInquiries: dealInquiriesReducer,
  adminActivities: adminActivitiesReducer,
});
