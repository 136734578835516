import axios from "axios";
import { ROOT_URL } from "../constants";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_CARS,
  CREATE_CAR,
  DELETE_CAR,
  CAR_LOADING
} from "./types";

// Get cars
export const getCars = () => dispatch => {
  dispatch({ type: CAR_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .get(ROOT_URL + "/v1/adminApi/cars")
    .then(async res => {
      dispatch({ type: GET_CARS, payload: res.data.cars });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Create car
export const createCar = (carData, history) => dispatch => {
  dispatch({ type: CAR_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post(ROOT_URL + "/v1/adminApi/cars", carData)
    .then(res => {
      dispatch({ type: CREATE_CAR, payload: res.data.car });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Update car
export const updateCar = (id, updateData) => dispatch => {
  axios
    .patch(ROOT_URL + "/v1/adminApi/cars/" + id, updateData)
    .then(async res => dispatch(getCars()))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Delete car
export const deleteCar = id => dispatch => {
  dispatch({ type: CAR_LOADING });
  axios
    .delete(ROOT_URL + "/v1/adminApi/cars/" + id)
    .then(async res => dispatch({ type: DELETE_CAR, payload: id }))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};
