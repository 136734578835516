import axios from "axios";
import { ROOT_URL } from "../constants";
import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_MODELS,
  CREATE_MODEL,
  DELETE_MODEL,
  MODEL_LOADING
} from "./types";

// Get models
export const getModels = () => dispatch => {
  dispatch({ type: MODEL_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .get(ROOT_URL + "/v1/adminApi/models")
    .then(async res => {
      dispatch({ type: GET_MODELS, payload: res.data.models });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Create model
export const createModel = modelData => dispatch => {
  dispatch({ type: MODEL_LOADING });
  dispatch({ type: CLEAR_ERRORS });
  axios
    .post(ROOT_URL + "/v1/adminApi/models", modelData)
    .then(res => {
      dispatch({ type: CREATE_MODEL, payload: res.data.model });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Update model
export const updateModel = (id, updateData) => dispatch => {
  axios
    .patch(ROOT_URL + "/v1/adminApi/models/" + id, updateData)
    .then(async res => dispatch(getModels()))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};

// Delete model
export const deleteModel = id => dispatch => {
  dispatch({ type: MODEL_LOADING });
  axios
    .delete(ROOT_URL + "/v1/adminApi/models/" + id)
    .then(async res => dispatch({ type: DELETE_MODEL, payload: id }))
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.response ? err.response.data : err
      });
    });
};
