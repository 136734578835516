import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

import Table from "../common/Table";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import Spinner from "../common/Spinner";
import DeleteModal from "../modals/DeleteModal";
import CreateEditCarModal from "../modals/CreateEditCarModal";
import {
  getCars,
  createCar,
  updateCar,
  deleteCar
} from "../../actions/carActions";
import { getBrands } from "../../actions/brandActions";
import { getModels } from "../../actions/modelActions";

import { carLabels } from "../../constants/tableData";
import { prepareCarFormData } from "../../utils/helpers";
import { ITEMS_PER_PAGE, car_template } from "../../constants";

class Cars extends Component {
  state = {
    edit: false,
    isOpen: true,
    activePage: 1,
    activeCar: null,
    deleteModal: false,
    createEditCarModal: false
  };

  componentDidMount() {
    this.props.getCars();
    this.props.getModels();
    this.props.getBrands();
  }

  changePage = activePage => {
    this.setState({ activePage });
  };

  toggleSidebar = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  openCreateEditCarModal = row => {
    car_template.rangePhotoUrls = [];
    const activeCar = row ? row : car_template;
    activeCar.photoIndex = 0;
    const edit = Boolean(row);

    this.setState({ createEditCarModal: true, activeCar, edit });
  };

  closeCreateEditCarModal = () => {
    this.setState({ createEditCarModal: false });
  };

  createEditCar = data => {
    const car = prepareCarFormData(data);

    if (this.state.edit) {
      this.props.updateCar(data._id, car);
    } else {
      this.props.createCar(car);
    }

    this.setState({ createEditCarModal: false });
  };

  openDeleteModal = activeCar => {
    this.setState({ deleteModal: true, activeCar });
  };

  closeDeleteModal = () => {
    this.setState({ deleteModal: false, activeCar: null });
  };

  deleteCar = () => {
    const { activeCar } = this.state;
    this.props.deleteCar(activeCar._id);
    this.closeDeleteModal();
  };

  render() {
    const {
      edit,
      isOpen,
      activeCar,
      activePage,
      deleteModal,
      createEditCarModal
    } = this.state;
    const { cars, loading } = this.props.cars;
    const { brands } = this.props.brands;
    const { models } = this.props.models;

    let sidebar = isOpen ? (
      <Sidebar isOpen={isOpen} toggleSidebar={this.toggleSidebar} />
    ) : (
      <i
        className="fas fa-bars"
        style={{ marginLeft: "10px", cursor: "pointer" }}
        onClick={this.toggleSidebar}
        title="Show"
      />
    );

    return (
      <div className="cars">
        <Navbar />
        {sidebar}
        <div className="container-fluid fixed-right">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="display-4 text-center">Cars</h1>
              <hr />
              <button
                className="btn btn-info"
                onClick={() => this.openCreateEditCarModal(null)}
                style={{ marginBottom: "5px" }}
              >
                Add Car
              </button>
              {loading || cars === null ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  <Table
                    labels={carLabels}
                    data={cars.slice(
                      (activePage - 1) * ITEMS_PER_PAGE,
                      activePage * ITEMS_PER_PAGE
                    )}
                    actions={[
                      {
                        type: "edit",
                        action: this.openCreateEditCarModal
                      },
                      {
                        type: "delete",
                        action: this.openDeleteModal
                      }
                    ]}
                  />
                  <Pagination
                    activePage={activePage}
                    totalItemsCount={cars.length}
                    onChange={this.changePage}
                    pageRangeDisplayed={5}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </React.Fragment>
              )}
              {createEditCarModal && (
                <CreateEditCarModal
                  edit={edit}
                  data={activeCar}
                  brands={brands}
                  models={models}
                  isOpen={createEditCarModal}
                  closeModal={this.closeCreateEditCarModal}
                  createEditCar={this.createEditCar}
                />
              )}
              {deleteModal && (
                <DeleteModal
                  title="Delete Car"
                  description="Are you sure you want to delete this car?"
                  isOpen={deleteModal}
                  closeModal={this.closeDeleteModal}
                  deleteAction={this.deleteCar}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Cars.propTypes = {
  getCars: PropTypes.func.isRequired,
  getBrands: PropTypes.func.isRequired,
  getModels: PropTypes.func.isRequired,
  createCar: PropTypes.func.isRequired,
  updateCar: PropTypes.func.isRequired,
  deleteCar: PropTypes.func.isRequired,
  cars: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  cars: state.cars,
  brands: state.brands,
  models: state.models,
  errors: state.errors
});

export default connect(mapStateToProps, {
  getCars,
  getBrands,
  getModels,
  createCar,
  updateCar,
  deleteCar
})(Cars);
