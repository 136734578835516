import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import InputGroup from "../common/InputGroup";
import ImageSelector from "../common/ImageSelector";
import SelectListGroup from "../common/SelectListGroup";
import TextAreaFieldGroup from "../common/TextAreaFieldGroup";
import { createEditTrimModalStyles } from "../../constants/modalStyles";
import { rangeTypes } from "../../constants";
import { capitalize } from "../../utils/helpers";

class CreateEditTrimModal extends React.Component {
  state = {
    data: this.props.data,
    errors: {},
  };

  onChange = (e) => {
    const { name, value } = e.target;
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  removePhotos = () => {
    const data = { ...this.state.data };
    data.photoUrls = [];
    this.setState({ data });
  };

  openDropzone = () => {
    this.dropzone.open();
  };

  uploadPhotos = (files) => {
    const data = { ...this.state.data };
    data.files = files;

    data.files.forEach((file) => {
      let reader = new FileReader();
      reader.onabort = () => console.log("file reading was aborted");
      reader.onerror = () => console.log("file reading has failed");

      reader.onload = (r) => {
        data.photoUrls.push(r.target.result);
        this.setState({ data });
      };
      reader.readAsDataURL(file);
    });

    this.setState({ data });
  };

  previousImage = () => {
    const data = { ...this.state.data };
    if (data.photoIndex > 0) {
      data.photoIndex -= 1;
    }
    this.setState({ data });
  };

  nextImage = () => {
    const data = { ...this.state.data };
    if (data.photoIndex < data.photoUrls.length - 1) {
      data.photoIndex += 1;
    }
    this.setState({ data });
  };

  isDataValid = () => {
    let { data } = this.state;
    let errors = {};
    let formIsValid = true;

    //Brand
    if (!data["brand"]) {
      formIsValid = false;
      errors["brand"] = "Cannot be empty";
    }
    //Model
    if (!data["model"]) {
      formIsValid = false;
      errors["model"] = "Cannot be empty";
    }
    //Type
    if (!data["type"]) {
      formIsValid = false;
      errors["type"] = "Cannot be empty";
    }
    //Name
    if (!data["name"]) {
      formIsValid = false;
      errors["name"] = "Cannot be empty";
    }
    //Files
    if (!data["files"] && data["photoUrls"].length === 0) {
      formIsValid = false;
      errors["files"] = "Cannot be empty";
    }

    this.setState({ errors });
    return formIsValid;
  };

  submit = (e) => {
    e.preventDefault();
    let data = { ...this.state.data };

    if (this.isDataValid()) {
      this.props.createEditTrim(data);
    }
  };

  render() {
    const { data, errors } = this.state;
    const { isOpen, edit, brands, models } = this.props;

    if (!data) return null;

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        style={createEditTrimModalStyles}
        contentLabel="Create Trim Modal"
      >
        <h3 className="display-4 text-center">{edit ? "Edit" : "Create"} Trim</h3>
        <hr />
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-md-12">
              <ImageSelector
                imgSrc={
                  data && data.photoUrls[data.photoIndex] ? data.photoUrls[data.photoIndex] : ""
                }
                previousImage={this.previousImage}
                nextImage={this.nextImage}
                removePhotos={this.removePhotos}
                handleChange={this.onChange}
                openDropzone={this.openDropzone}
                error={errors.files}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Brand Name</label>
              <SelectListGroup
                name="brand"
                value={data.brand}
                onChange={this.onChange}
                options={brands}
                error={errors.brand}
              />
            </div>
            <div className="col-md-4">
              <label>Model Name</label>
              <SelectListGroup
                name="model"
                value={data.model}
                onChange={this.onChange}
                options={models.filter((model) => model.brand === data.brand)}
                error={errors.model}
              />
            </div>
            <div className="col-md-4">
              <label>Type</label>
              <SelectListGroup
                name="type"
                value={capitalize(data.type)}
                onChange={this.onChange}
                options={rangeTypes}
                error={errors.type}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <label>Name</label>
              <InputGroup
                name="name"
                type="text"
                value={data.name}
                onChange={this.onChange}
                error={errors.name}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Description</label>
              <TextAreaFieldGroup
                name="description"
                value={data.description}
                onChange={this.onChange}
              />
            </div>
          </div>
          <input type="submit" value="Save" className="btn btn-info" />
          <Dropzone
            ref={(input) => {
              this.dropzone = input;
            }}
            onDrop={(files) => this.uploadPhotos(files)}
            multiple={true}
            accept="image/*"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </form>
      </Modal>
    );
  }
}

CreateEditTrimModal.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  createEditTrim: PropTypes.func,
  closeModal: PropTypes.func,
};

export default CreateEditTrimModal;
