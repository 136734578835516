import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import Dropzone from "react-dropzone";
import InputGroup from "../common/InputGroup";
import ImageSelector from "../common/ImageSelector";
import { createEditBrandModalStyles } from "../../constants/modalStyles";

class CreateEditBrandModal extends React.Component {
  state = {
    data: this.props.data,
    errors: {}
  };

  onChange = e => {
    const { name, value } = e.target;
    const data = { ...this.state.data };
    data[name] = value;
    this.setState({ data });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  removePhoto = () => {
    const data = { ...this.state.data };
    data.photoUrl = null;
    data.file = null;
    this.setState({ data });
  };

  openDropzone = () => {
    this.dropzone.open();
  };

  uploadPhoto = files => {
    const data = { ...this.state.data };
    data.file = files[0];

    let reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = r => {
      data.photoUrl = r.target.result;
      this.setState({ data });
    };
    reader.readAsDataURL(files[0]);

    this.setState({ data });
  };

  isDataValid = () => {
    let { data } = this.state;
    let errors = {};
    let formIsValid = true;

    //Name
    if (!data["name"]) {
      formIsValid = false;
      errors["name"] = "Cannot be empty";
    }
    //File
    if (!data["file"] && !data["photoUrl"]) {
      formIsValid = false;
      errors["file"] = "Cannot be empty";
    }

    this.setState({ errors });
    return formIsValid;
  };

  submit = e => {
    e.preventDefault();
    if (this.isDataValid()) {
      this.props.createEditBrand(this.state.data);
    }
  };

  render() {
    const { data, errors } = this.state;
    const { isOpen, edit } = this.props;

    if (!data) return null;

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        style={createEditBrandModalStyles}
        contentLabel="Create Brand Modal"
      >
        <h3 className="display-4 text-center">
          {edit ? "Edit" : "Create"} Brand
        </h3>
        <hr />
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-md-12">
              <ImageSelector
                imgSrc={data && data.photoUrl ? data.photoUrl : ""}
                removePhotos={this.removePhoto}
                handleChange={this.onChange}
                openDropzone={this.openDropzone}
                error={errors.file}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <label>Name</label>
              <InputGroup
                name="name"
                type="text"
                placeholder="Brand Name"
                value={data.name}
                onChange={this.onChange}
                error={errors.name}
              />
            </div>
          </div>
          <input type="submit" value="Save" className="btn btn-info" />
          <Dropzone
            ref={input => {
              this.dropzone = input;
            }}
            onDrop={files => this.uploadPhoto(files)}
            multiple={false}
            accept="image/*"
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
              </div>
            )}
          </Dropzone>
        </form>
      </Modal>
    );
  }
}

CreateEditBrandModal.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  edit: PropTypes.bool,
  createEditBrand: PropTypes.func,
  closeModal: PropTypes.func
};

export default CreateEditBrandModal;
