import React from "react";
import InputGroup from "./InputGroup";

const OptionInputs = props => {
  const { options } = props;
  return options.map((option, index) => {
    let nameId = `option-${index}`;
    return (
      <div className="row" key={index}>
        <div className="col-md-12">
          <small>Option #{index + 1}</small>
          <InputGroup
            name={nameId}
            value={option}
            dataId={index}
            className="option"
            onChange={props.onChange}
          />
        </div>
      </div>
    );
  });
};

export default OptionInputs;
