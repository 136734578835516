import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

import Table from "../common/Table";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import Spinner from "../common/Spinner";
import ChatModal from "../modals/ChatModal";
import EditDealInquiryModal from "../modals/EditDealInquiryModal";
import { getDealInquiries, updateDealInquiry } from "../../actions/dealInquiryActions";
import { createInquiryMessage } from "../../actions/inquiryMessageActions";

import { prepareDealInquiryFormData } from "../../utils/helpers";
import { dealInquiryLabels } from "../../constants/tableData";
import { ITEMS_PER_PAGE, inquiryStatuses } from "../../constants";
import { capitalize } from "../../utils/helpers";

class DealInquiries extends Component {
  state = {
    isOpen: true,
    activePage: 1,
    activeTab: "pending",
    activeDealInqury: null,
    editDealInquiryModal: false,
    chatModal: false
  };

  componentDidMount() {
    this.props.getDealInquiries();
  }

  changePage = activePage => {
    this.setState({ activePage });
  };

  changeTab = activeTab => () => {
    this.setState({ activeTab });
    this.changePage(1);
  };

  toggleSidebar = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  openEditDealInquiryModal = row => {
    this.setState({ editDealInquiryModal: true, activeDealInqury: row });
  };

  closeEditDealInquiryModal = () => {
    this.setState({ editDealInquiryModal: false });
  };

  updateDealInquiry = data => {
    const dealInquiry = prepareDealInquiryFormData(data);

    this.props.updateDealInquiry(data._id, dealInquiry);
    this.setState({ editDealInquiryModal: false });
  };

  openChatModal = row => {
    this.setState({ chatModal: true, activeDealInqury: row });
  };

  closeChatModal = () => {
    this.setState({ chatModal: false });
  };

  createInquiryMessage = message => {
    this.props.createInquiryMessage(this.state.activeDealInqury._id, message);
  };

  render() {
    const {
      isOpen,
      activePage,
      activeTab,
      chatModal,
      activeDealInqury,
      editDealInquiryModal
    } = this.state;
    const { dealInquiries, loading } = this.props.dealInquiries;

    const filteredDealInquiries = dealInquiries
      ? dealInquiries.filter(dealInquiry => dealInquiry.status === activeTab)
      : [];

    const tabs = inquiryStatuses.map(inquiry => (
      <li
        key={inquiry.name}
        role="presentation"
        className="nav-item"
        onClick={this.changeTab(inquiry.name)}
      >
        <span className={inquiry.name === activeTab ? "nav-link active" : "nav-link"}>
          {capitalize(inquiry.name)}
        </span>
      </li>
    ));

    let sidebar = isOpen ? (
      <Sidebar isOpen={isOpen} toggleSidebar={this.toggleSidebar} />
    ) : (
      <i
        className="fas fa-bars"
        style={{ marginLeft: "10px", cursor: "pointer" }}
        onClick={this.toggleSidebar}
        title="Show"
      />
    );

    return (
      <div className="dealInquiries">
        <Navbar />
        {sidebar}
        <div className="container-fluid fixed-right">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="display-4 text-center">Deal Inquiries</h1>
              <hr />
              <h4>
                <ul className="nav nav-tabs">{tabs}</ul>
              </h4>
              {loading || dealInquiries === null ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  <Table
                    labels={dealInquiryLabels}
                    data={filteredDealInquiries.slice(
                      (activePage - 1) * ITEMS_PER_PAGE,
                      activePage * ITEMS_PER_PAGE
                    )}
                    actions={[
                      {
                        type: "edit",
                        action: this.openEditDealInquiryModal
                      },
                      {
                        type: "chat",
                        action: this.openChatModal
                      }
                    ]}
                  />
                  <Pagination
                    activePage={activePage}
                    totalItemsCount={dealInquiries.length}
                    onChange={this.changePage}
                    pageRangeDisplayed={5}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </React.Fragment>
              )}
              {editDealInquiryModal && (
                <EditDealInquiryModal
                  data={activeDealInqury}
                  isOpen={editDealInquiryModal}
                  closeModal={this.closeEditDealInquiryModal}
                  updateDealInquiry={this.updateDealInquiry}
                />
              )}
              {chatModal && (
                <ChatModal
                  key={activeDealInqury.inquiryMessages.length}
                  inquiryMessages={activeDealInqury.inquiryMessages}
                  isOpen={chatModal}
                  closeModal={this.closeChatModal}
                  createInquiryMessage={this.createInquiryMessage}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DealInquiries.propTypes = {
  createInquiryMessage: PropTypes.func.isRequired,
  getDealInquiries: PropTypes.func.isRequired,
  updateDealInquiry: PropTypes.func.isRequired,
  dealInquiries: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  dealInquiries: state.dealInquiries,
  errors: state.errors
});

export default connect(mapStateToProps, {
  getDealInquiries,
  updateDealInquiry,
  createInquiryMessage
})(DealInquiries);
