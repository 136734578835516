import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Pagination from "react-js-pagination";

import Table from "../common/Table";
import Navbar from "../layout/Navbar";
import Sidebar from "../layout/Sidebar";
import Spinner from "../common/Spinner";
import InputGroup from "../common/InputGroup";
import DeleteModal from "../modals/DeleteModal";
import CreateEditBrandModal from "../modals/CreateEditBrandModal";
import { getBrands, createBrand, updateBrand, deleteBrand } from "../../actions/brandActions";

import { brandLabels } from "../../constants/tableData";
import { prepareBrandFormData } from "../../utils/helpers";
import { ITEMS_PER_PAGE, brand_template } from "../../constants";

class Brands extends Component {
  state = {
    edit: false,
    isOpen: true,
    search: "",
    activePage: 1,
    activeBrand: null,
    deleteModal: false,
    createEditBrandModal: false,
  };

  componentDidMount() {
    this.props.getBrands();
  }

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  changePage = (activePage) => {
    this.setState({ activePage });
  };

  toggleSidebar = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  openCreateEditBrandModal = (row) => {
    const activeBrand = row ? row : brand_template;
    const edit = Boolean(row);

    this.setState({ createEditBrandModal: true, activeBrand, edit });
  };

  closeCreateEditBrandModal = () => {
    this.setState({ createEditBrandModal: false });
  };

  createEditBrand = (data) => {
    const brand = prepareBrandFormData(data);

    if (this.state.edit) {
      this.props.updateBrand(data._id, brand);
    } else {
      this.props.createBrand(brand);
    }

    this.setState({ createEditBrandModal: false });
  };

  openDeleteModal = (activeBrand) => {
    this.setState({ deleteModal: true, activeBrand });
  };

  closeDeleteModal = () => {
    this.setState({ deleteModal: false, activeBrand: null });
  };

  deleteBrand = () => {
    const { activeBrand } = this.state;
    this.props.deleteBrand(activeBrand._id);
    this.closeDeleteModal();
  };

  render() {
    const {
      edit,
      isOpen,
      search,
      activePage,
      activeBrand,
      deleteModal,
      createEditBrandModal,
    } = this.state;
    const { brands, loading } = this.props.brands;

    if (!brands) return null;

    let sidebar = isOpen ? (
      <Sidebar isOpen={isOpen} toggleSidebar={this.toggleSidebar} />
    ) : (
      <i
        className="fas fa-bars"
        style={{ marginLeft: "10px", cursor: "pointer" }}
        onClick={this.toggleSidebar}
        title="Show"
      />
    );

    let filteredBrands = brands.filter((brand) => {
      return brand.name.toLowerCase().indexOf(search.toLowerCase()) !== -1;
    });

    return (
      <div className="brands">
        <Navbar />
        {sidebar}
        <div className="container-fluid fixed-right">
          <div className="row">
            <div className="col-lg-12">
              <h1 className="display-4 text-center">Brands</h1>
              <hr />
            </div>
            <div className="col-lg-9">
              <button
                className="btn btn-info"
                onClick={() => this.openCreateEditBrandModal(null)}
                style={{ marginBottom: "5px" }}
              >
                Add Brand
              </button>
            </div>
            <div className="col-lg-3">
              <InputGroup
                name="search"
                type="text"
                placeholder="Search"
                value={search}
                onChange={this.onChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              {loading || brands === null ? (
                <Spinner />
              ) : (
                <React.Fragment>
                  <Table
                    labels={brandLabels}
                    data={filteredBrands.slice(
                      (activePage - 1) * ITEMS_PER_PAGE,
                      activePage * ITEMS_PER_PAGE
                    )}
                    actions={[
                      {
                        type: "edit",
                        action: this.openCreateEditBrandModal,
                      },
                      {
                        type: "delete",
                        action: this.openDeleteModal,
                      },
                    ]}
                  />
                  <Pagination
                    activePage={activePage}
                    totalItemsCount={brands.length}
                    onChange={this.changePage}
                    pageRangeDisplayed={5}
                    itemsCountPerPage={ITEMS_PER_PAGE}
                    itemClass="page-item"
                    linkClass="page-link"
                  />
                </React.Fragment>
              )}
              {createEditBrandModal && (
                <CreateEditBrandModal
                  edit={edit}
                  data={activeBrand}
                  isOpen={createEditBrandModal}
                  closeModal={this.closeCreateEditBrandModal}
                  createEditBrand={this.createEditBrand}
                />
              )}
              {deleteModal && (
                <DeleteModal
                  title="Delete Brand"
                  description="Are you sure you want to delete this brand?"
                  isOpen={deleteModal}
                  closeModal={this.closeDeleteModal}
                  deleteAction={this.deleteBrand}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Brands.propTypes = {
  getBrands: PropTypes.func.isRequired,
  createBrand: PropTypes.func.isRequired,
  updateBrand: PropTypes.func.isRequired,
  deleteBrand: PropTypes.func.isRequired,
  brands: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  brands: state.brands,
  errors: state.errors,
});

export default connect(mapStateToProps, {
  getBrands,
  createBrand,
  updateBrand,
  deleteBrand,
})(Brands);
