import React from "react";
import PropTypes from "prop-types";
import Modal from "react-modal";
import moment from "moment";
import InputGroup from "../common/InputGroup";
import { chatModalStyles } from "../../constants/modalStyles";

class ChatModal extends React.Component {
  state = {
    inquiryMessages: this.props.inquiryMessages,
    message: "",
    errors: {},
  };

  onChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  };

  closeModal = () => {
    this.props.closeModal();
  };

  submit = (e) => {
    e.preventDefault();

    if (this.state.message !== "") {
      let inquiryMessages = [
        ...this.state.inquiryMessages,
        {
          message: this.state.message,
          createdAt: new Date(),
          userId: false,
        },
      ];
      this.props.createInquiryMessage(this.state.message);
      this.setState({ message: "", inquiryMessages });
    }
  };

  render() {
    const { inquiryMessages, message, errors } = this.state;
    const { isOpen } = this.props;

    let messages = inquiryMessages.map((m, index) => {
      if (m.userId) {
        return (
          <div key={index} className="message-box-holder">
            <div className="message-sender">
              {m.firstName} {m.lastName}
            </div>
            <div className="message-box message-partner">
              {m.message}
              <span className="tooltiptext tooltiptext-right">
                {moment(m.createdAt).format("hh:mm:ss MM-DD-YYYY")}
              </span>
            </div>
          </div>
        );
      } else {
        return (
          <div key={index} className="message-box-holder">
            <div className="message-box">
              {m.message}
              <span className="tooltiptext tooltiptext-left">
                {moment(m.createdAt).format("hh:mm:ss MM-DD-YYYY")}
              </span>
            </div>
          </div>
        );
      }
    });

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={this.closeModal}
        style={chatModalStyles}
        contentLabel="Create Brand Modal"
      >
        <form onSubmit={this.submit}>
          <div className="row">
            <div className="col-md-12">
              <div className="chat-messages">{messages}</div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-10">
              <InputGroup
                name="message"
                type="text"
                placeholder="Message"
                value={message}
                onChange={this.onChange}
                error={errors.message}
              />
            </div>
            <div className="col-md-2">
              <input type="submit" value="Send" className="btn btn-info" />
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

ChatModal.propTypes = {
  data: PropTypes.object,
  isOpen: PropTypes.bool,
  createInquiryMessage: PropTypes.func,
  closeModal: PropTypes.func,
};

export default ChatModal;
