import { GET_INQUIRIES, INQUIRY_LOADING } from "../actions/types";

const initialState = {
  inquiries: null,
  loading: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case INQUIRY_LOADING:
      return {
        ...state,
        loading: true
      };
    case GET_INQUIRIES:
      return {
        ...state,
        inquiries: action.payload,
        loading: false
      };
    default:
      return state;
  }
};
